import React, { Fragment, useEffect, useState } from "react";
import Modal from "components/utils/Modal";
import ServicesService from "services/ServicesService";

const CreateService = ({ open, onCloseModal, onCreated }) => {
  const [block, setBlock] = useState(false);

  const [data, setData] = useState({
    name: "",
    amount: "",
    description: "",
  });

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setBlock(false);

    setData({
      ...data,
      [name]: value,
    });
  };

  const addServices = async () => {
    setBlock(true);
    await ServicesService.create(data);
    onCreated();
    setData({
      name: "",
      amount: "",
      description: "",
    });
    onCloseModal();
    setBlock(false);
  };

  return (
    <div>
      <Modal
        open={open}
        onCloseModal={onCloseModal}
        title={<>Add Service</>}
        body={
          <>
            <div className="form-group mt-5">
              <label className="required form-label">Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Service Name"
                name="name"
                id="name"
                onChange={handleChange}
              />
            </div>

            <div className="form-group mt-5">
              <label className="required form-label">Amount</label>
              <input
                type="number"
                className="form-control"
                placeholder="Enter Amount"
                name="amount"
                id="amount"
                onChange={handleChange}
              />
            </div>

            <div className="form-group mt-5">
              <label className="form-label">Description</label>
              <textarea
                rows="3"
                type="text"
                className="form-control"
                placeholder="Enter  Description"
                name="description"
                id="description"
                onChange={handleChange}
              />
            </div>

            <button
              type="reset"
              className="btn btn-primary mr-2 mt-5"
              style={{ marginRight: "1rem" }}
              onClick={addServices}
              disabled={block}
            >
              Create
            </button>
            <button
              type="reset"
              className="btn btn-secondary  mt-5 "
              onClick={onCloseModal}
            >
              Cancel
            </button>
          </>
        }
      />
    </div>
  );
};

export default CreateService;
