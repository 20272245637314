import Confirmation from "components/utils/Confirmation";
import Table from "components/utils/Table";
import PermissionAbility from "helpers/PermissionAbility";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import PartService from "services/PartService";
import ImportFile from "../ImportFile";
import Filter from "./Filter";

const LcPartSummery = () => {
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [partId, setPartId] = useState();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [serviceId, setServiceId] = useState([]);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [partSummery, setPartSummery] = useState([]);
  const [data, setData] = useState(null);
  const [block, setBlock] = useState(false);
  const [modalData, setModalData] = useState({});
  const [isModal, setIsModal] = useState(false);

  const [open, setOpen] = useState(false);
  const [filtervalue, setFilterValue] = useState(null);

  const onOpenModal = () => setOpen(true);

  const filterdata = (data) => {
    setFilter(false);
    setFilterValue(data);
    getLcPartSummery(data);
  };

  const deletePart = async (id) => {
    setBlock(true);
    await PartService.deleteLcPart(id);
    setBlock(false);
    setConfirmDelete(false);
    getLcPartSummery();
  };


  const handleModal = (data) => {
    setModalData(data);
    setIsModal(true);
  };

  const columns = [
    {
      name: "Part Name",
      selector: (row) => row.part_name ?? "--",
      sortable: true,
      field: "name",
    },

    {
      name: "Part Number",
      selector: (row) => row.part_number ?? "--",
      sortable: true,
      field: "part_number",
    },

    {
      name: "LC Invoice",
      selector: (row) => row.lc_invoice ?? "--",
      width: "13%",
      sortable: true,
      field: "lc_invoice",
    },

    {
      name: "Model",
      selector: (row) => row.machine_model ?? "--",
      sortable: true,
      field: "machine_model",
    },

    {
      name: "Qty",
      selector: (row) => row.qty,
      sortable: true,
      field: "qty",
    },

    {
      name: "Received Qty",
      selector: (row) => row.received_qty ?? "--",
      sortable: true,
      field: "received_qty",
    },

    {
      name: "Status",
      selector: (row) => {
        switch (row?.is_stock) {
          case 1:
            return "Stock In";
          case 2:
            return "Not stocked";
          default:
            return "Not stocked yet";
        }
      },
    },

    {
      name: "Action",
      selector: (row) => row.total_amount,
      format: (row) => (
        <>
          <span className="text-end">
            <PermissionAbility permission="lc_part_summery_show">
              <div
                onClick={() => handleModal(row)}
                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                data-toggle="tooltip"
                title="Details"
              >
                <i className="fa fa-eye"></i>
              </div>
            </PermissionAbility>
          </span>
          <PermissionAbility permission="parts_delete">
            <Link
              to="#"
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
              onClick={() => {
                setPartId(row.id);
                setConfirmDelete(true);
              }}
            >
              <i className="fa fa-trash"></i>
            </Link>
          </PermissionAbility>
        </>
      ),
    },
  ];

  const lcSummeryExport = async () => {
    setLoading(true);
    let data = await PartService.lcSummeryExport(filtervalue);
    window.location.href = data;
    setLoading(false);
  };

  const getLcPartSummery = async (filters) => {
    setPartSummery(await PartService.getPartSummery(filters));
    setLoading(false);
  };

  const onCloseModal = () => {
    setOpenAddModal(false);
    setOpenEditModal(false);
  };

  useEffect(() => {
    getLcPartSummery();
  }, []);

  return (
    <>
      <div className="post d-flex flex-column-fluid">
        <div className="container-xxl">
          <Table
            name="LC part Summery"
            isLoading={loading}
            data={partSummery}
            onClickButton={onOpenModal}
            callbackButtons={[
              {
                name: "Filter",
                callback: () => {
                  setFilter(!filter);
                },
                permission: null,
              },
              {
                name: "Import",
                callback: () => {
                  setOpenImportModal(true);
                },
                permission: null,
              },
              {
                name: "Export",
                callback: () => {
                  lcSummeryExport();
                },
                permission: null,
              },
            ]}
            columns={columns}
            onFilter={getLcPartSummery}
          />
        </div>
      </div>
      <Filter
        enable={filter}
        onClickOutside={() => {
          setFilter(!filter);
        }}
        onChange={(data) => {
          filterdata(data);
        }}
      />
      <ImportFile
        open={openImportModal}
        onCloseModal={() => setOpenImportModal(false)}
        onImported={getLcPartSummery}
      />
      <Confirmation
        open={confirmDelete}
        onConfirm={() => {
          setConfirmDelete(false);
          deletePart(partId);
        }}
        onCancel={() => setConfirmDelete(false)}
      />
      {/* modal div */}
      <div>
        <Modal
          show={isModal}
          onHide={() => setIsModal(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title
              id="contained-modal-title-vcenter"
              className="text-uppercase"
            >
              {modalData.part_name} <span>({modalData.part_number})</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <h4>{modalData.part_name}</h4> */}
            <div className="row">
              <div className="col-md-6">
                <div>
                  {" "}
                  <span className="fw-bold">LC Quantity :</span>{" "}
                  {Math.round(modalData.qty)}
                </div>
              </div>

              <div className="col-md-6">
                <div>
                  {" "}
                  <span className="fw-bold">Received Quantity :</span>{" "}
                  {Math.round(modalData.received_qty)}
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-6">
                <div>
                  {" "}
                  <span className="fw-bold">Unit Price :</span>{" "}
                  {modalData.unit_price}
                </div>
                <div>
                  {" "}
                  <span className="fw-bold">Total Amount :</span>{" "}
                  {modalData.total_amount}
                </div>
              </div>

              <div className="col-md-6">
                <div>
                  {" "}
                  <span className="fw-bold">Bin Location :</span>{" "}
                  {modalData.bin_location}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => {
                setIsModal(false);
              }}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </div>
      {/* end modal div */}
    </>
  );
};

export default LcPartSummery;
