import Table from "components/utils/Table";
import PermissionAbility from "helpers/PermissionAbility";
import { useState } from "react";
import Moment from "react-moment";
import { Link, useNavigate } from "react-router-dom";
import RequisitionService from "services/RequisitionService";
import CreateModal from "./CreateModal";
import RequisitionFilter from "./RequisitionFilter";
import Confirmation from "components/utils/Confirmation";


const Requisitions = () => {
  const [loading, setLoading] = useState(true);
  const [requisitions, setRequisitions] = useState([]);
  const [open, setOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [reqId, setReqId] = useState({});

  

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const [filter, setFilter] = useState(false);

  const filterdata = (data) => {
    setFilter(false);
    getRequisitions(data);
  };

  const columns = [
    {
      name: "Id",
      selector: (row) => row?.rq_number,
      sortable: true,
    },

    {
      name: "Company",
      selector: (row) => row?.company?.name,
      sortable: true,
      field: "name",
      format: (row) => (
        <div className="d-flex align-items-center">
          <div className="d-flex justify-content-start flex-column">
            <Link
              to={"/panel/companies/" + row?.company?.id}
              className="text-dark fw-bolder text-hover-primary"
            >
              {row?.company?.name}
            </Link>
          </div>
        </div>
      ),
    },
    {
      name: "Quotation Type",
      selector: (row) =>
        row?.quotation?.type?.replaceAll("_", " ")?.capitalize(),
      sortable: true,
      field: "id",
    },

    {
      name: "Part Quantity",
      selector: (row) =>
        row?.part_items?.reduce((partialSum, a) => partialSum + a.quantity, 0),
      format: (row) => (
        <div className="mt-2">
          {row?.part_items?.reduce(
            (partialSum, a) => partialSum + a.quantity,
            0
          )}
        </div>
      ),
      sortable: true,
      field: "expected_delivery",
    },
    {
      name: "Sub Total",
      selector: (row) => row?.sub_total,
      format: (row) => (
        <div>{row?.sub_total ? row?.sub_total + "tk" : "--"}</div>
      ),
      sortable: true,
      field: "sub_total",
    },

    {
      name: "Vat & Other",
      selector: (row) => row?.quotation?.type,
      format: (row) => (
        <div>{row?.quotation?.type == "purchase_request" ? row?.grand_total-row?.sub_total  + "tk" : "0"}</div>
      ),
      sortable: true,
      field: "Vat & Other",
    },

    // {
    //   name: "Grand Total",
    //   selector: (row) => row?.quotation?.type,
    //   format: (row) => (
    //     <div>{row?.quotation?.type == "purchase_request" ? row?.grand_total+ "tk" : "0"}</div>
    //   ),
    //   sortable: true,
    //   field: "Vat & Other",
    // },

    {
      name: "lock",
      selector: (row) => row?.locked_at,
      format: (row) => (
        <div className="mt-2">{row?.locked_at ? "Locked" : "Not locked"}</div>
      ),
      sortable: true,
      field: "id",
    },
    {
      name: "Status",
      selector: (row) => row?.status,
      sortable: true,
      field: "role",
      format: (row) => (
        <>
          {row?.status == "pending" && (
            <div className="mt-2 text-white bg-warning p-1 px-2 rounded">
              Pending
            </div>
          )}
          {row?.status == "approved" && (
            <div className="mt-2 text-white bg-success p-1 px-2 rounded">
              Approved
            </div>
          )}
          {row?.status == "rejected" && (
            <div className="mt-2 text-white bg-danger p-1 px-2 rounded">
              Rejected
            </div>
          )}
          {row?.status == null && (
            <div className="mt-2 text-white bg-success p-1 px-2 rounded">
              --
            </div>
          )}
        </>
      ),
    },

    {
      name: "IN Number",
      selector: (row) => row?.invoice,
      sortable: true,
      field: "id",
      format: (row) => (
        <div className="mt-2">
          {row?.invoice ? row?.invoice : "No invoice yet"}
        </div>
      ),
    },

    {
      name: "Created At",
      selector: (row) => row?.created_at,
      sortable: true,
      field: "created_at",
      format: (row) => (
        <div className="mt-2">
          <Moment format="D MMMM YYYY">{row?.created_at}</Moment>
        </div>
      ),
    },

    {
      name: "Action",
      selector: (row) => row.status,
      format: (row) => (
        <span className="text-end">
          <PermissionAbility permission="requisitions_show">
            <Link
              to={"/panel/requisitions/" + row.id}
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
            >
              <i className="fa fa-eye"></i>
            </Link>
          </PermissionAbility>
          {!row?.invoice && (
          <PermissionAbility permission="requisitions_delete">
              <Link
                to="#"
                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                onClick={() => {
                  setReqId(row.id);
                  setConfirmDelete(true);
                }}
              >
                <i className="fa fa-trash"></i>
              </Link>
            </PermissionAbility>
           )}
        </span>
      ),
    },
  ];

  const getRequisitions = async (filters) => {
    setRequisitions(await RequisitionService.getAll(filters));
    setLoading(false);
  };

  const deleteRequisition = async (reqId) => {
    await RequisitionService.remove(reqId);
    getRequisitions();
    setLoading(false);
  };

  let navigate = useNavigate();

  const routeChange = () => {
    let path = `create`;
    navigate(path);
  };

  return (
    <>
      <div className="post d-flex flex-column-fluid">
        <div className="container-xxl">
          <Table
            name="Requisition"
            buttonName="Filter"
            onClickButton={() => setFilter(!filter)}
            isLoading={loading}
            data={requisitions}
            columns={columns}
            onFilter={getRequisitions}
          />
        </div>
      </div>

      <RequisitionFilter
        onClickOutside={() => {
          setFilter(!filter);
        }}
        enable={filter}
        onChange={(data) => {
          filterdata(data);
        }}
      />
      <Confirmation
        open={confirmDelete}
        onConfirm={() => {
          setConfirmDelete(false);
          deleteRequisition(reqId);
        }}
        onCancel={() => setConfirmDelete(false)}
      />
    </>
  );
};

export default Requisitions;
