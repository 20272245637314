import http from "../http-common";

const getAll = async (filters) => {
  const res = await http.get("/all-parts", {
    params: filters,
  });
  return res.data;
};

const getStock = async (filters) => {
  const res = await http.get("/part-stocks", {
    params: filters,
  });
  return res.data;
};

const getLcPartHistory = async(data) => {
  const res = await http.post(`/lc-part-history`, data);
  return res.data;
};

const getGatePassPart = async (filters) => {
  const res = await http.get("/gate-pass-parts", {
    params: filters,
  });
  return res.data;
};

const get = async (id) => {
  const res = await http.get(`/parts/${id}`);
  return res.data;
};

const previousQuantity = async(data) => {
  const res = await http.post(`/part-history`, data);
  return res.data;
};

const create = async (data) => {
  const res = await http.post("/parts", data);
  return res.data;
};

const createStock = async (data) => {
  const res = await http.post("/part-stock-store", data);
  return res.data;
};


const update = async (id, data) => {
  data.append("_method", "PUT");
  const res = await http.post(`/parts/${id}`, data);
  return res.data;
};

const remove = async (id) => {
  const res = await http.delete(`/parts/${id}`);
  return res.data;
};

const deleteLcPart = async (id) => {
  const res = await http.delete(`/lc-part-summery/${id}`);
  return res.data;
}

const importFile = async (data) => {
  const res = await http.post("/parts-import", data);
  return res.data;
};

const updatePartInfo = async (id, data) => {
  const res = await http.post(`/parts/info/${id}`, data);
  return res?.data;
};
// get foc parts
const getFoc = async (filters) => {
  const res = await http.get("/foc-parts", {
    params: filters,
  });
  return res.data;
};

// get sellable parts
const getSellable = async (filters) => {
  const res = await http.get("/sellable-parts", {
    params: filters,
  });
  return res.data;
};

//client module

const getClientPart = async (filters) => {
  const res = await http.get("/client-parts", {
    params: filters,
  });
  return res.data;
};

//lc part summery

const getPartSummery = async (filters) => {
  const res = await http.get("/lc-part-summery", {
    params: filters,
  });
  return res.data;
};

const lcSummeryExport = async (filters)=>{
  const res = await http.get("lc-summery-export", {
    params: filters
  });
  return res.data.url;
}

const exportParts = async (filters)=>{
  const res = await http.get(`/parts-export`, {
    params: filters,
  });
  return res?.data?.url;
}

const exportStockParts = async (filters)=>{
  const res = await http.get(`/stock-parts-export`, {
    params: filters,
  });
  return res?.data?.url;
}

const exportIssueFile = async (uuid)=>{
  const res = await http.get(`/export-issue-file/${uuid}`);
  return res?.data?.url;
}

const getStockOption = async (params) => {
  const res = await http.get(`/parts-stocks-option`, { params });
  return res?.data;
};

const getLc = async (part_name_id) => {
  const res = await http.get("/parts-stocks-option/lc/" + part_name_id);
  return res.data;
};

const getWarehouses = async (partNameId, lcId) => {
  const res = await http.get(
    "/parts-stocks-option/warhouses/" + partNameId + "/" + lcId
  );
  return res?.data;
};


const getActualPrice = async (partNameId) => {
  const res = await http.get(
    "/actual-unit-price/" + partNameId
  );
  return res?.data;
};



const getBinLocations = async (partNameId, lcId, warehouseId) => {
  const res = await http.get(
    "/parts-stocks-option/bin-location/" + partNameId + "/" + lcId + "/" + warehouseId
  );
  return res?.data;
};

const PartService = {
  getAll,
  getStock,
  getLcPartHistory,
  get,
  previousQuantity,
  updatePartInfo,
  create,
  createStock,
  update,
  remove,
  deleteLcPart,
  importFile,
  getGatePassPart,
  getClientPart,
  //get foc part
  getFoc,
  //get sellable parts
  getSellable,
  //lc part summery
  getPartSummery,
  lcSummeryExport,
  exportParts,
  exportStockParts,
  exportIssueFile,

  getStockOption,

  getLc,
  getWarehouses,
  getBinLocations,
  getActualPrice
};

export default PartService;
