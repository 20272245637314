import { Activities } from "components/utils/Activities";
import PermissionAbility from "helpers/PermissionAbility";
import { useEffect, useState } from "react";
import Moment from "react-moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import InvoiceService from "services/InvoiceService";
import InvoicePartItems from "./partiItems/Index";
import InvoiceCreatePayment from "./paymentHistories/Create";
import PaymentHistores from "./paymentHistories/Index";
const ShowInvoice = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [invoice, setInvoice] = useState({});
  const [total, setTotal] = useState(0);
  const [totalPayment, setTotalPayment] = useState(0);
  const [paymentHistories, setPaymentHistories] = useState([]);
  const [active, setActive] = useState("part_items");
  const [tab, setTab] = useState("part_items");
  const [open, setOpen] = useState(false);

  const getInvoice = async () => {
    let res = await InvoiceService.get(id);
    setInvoice(res);
  };

  useEffect(() => {
    invoice?.part_items?.length == 0 && setActive("payment_histories");
  }, [invoice]);

  const getPaymentHistories = async () => {
    let res = await InvoiceService.getPaymentHistories(id);
    setPaymentHistories(res.data);
    setTotalPayment(
      res?.data?.reduce(
        (partialSum, a) => parseInt(partialSum) + parseInt(a.amount),
        0
      )
    );
  };
  const onCloseModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!open) {
      getPaymentHistories();
    }
  }, [open]);

  useEffect(() => {
    if (id) getInvoice();
    getPaymentHistories();
  }, [id]);

  return (
    <>
      <div className="d-flex flex-column-fluid">
        <div className="container">
          <div className="row">
            <div className="col-xl-3">
              <div className="card card-custom">
                <div className="card-header">
                  <div className="card-title">
                    <h3 className="card-label">
                      <button
                        className="btn btn-sm btn-dark "
                        style={{ marginRight: "0.75rem" }}
                        onClick={() => navigate(-1)}
                      >
                        <i className="fa fa-arrow-left"></i>Back
                      </button>
                      Details
                    </h3>
                  </div>
                </div>

                <div className="card-body py-4">
                  <div className="fw-bolder mt-5">Invoice Number</div>
                  <div className="text-gray-600">{invoice?.invoice_number}</div>
                  {invoice?.part_items?.length > 0 && (
                    <>
                      <div className="fw-bolder mt-5">Invoice Status</div>
                      {invoice?.requisition?.type !== "claim_report" ? (
                        <>
                          <div className="text-gray-600">
                            {invoice?.part_items?.reduce(
                              (partialSum, a) =>
                                partialSum + parseInt(a.total_value),
                              0
                            ) ==
                            paymentHistories?.reduce(
                              (partialSum, a) =>
                                partialSum + parseInt(a.amount),
                              0
                            ) ? (
                              <span className="badge badge-light-success">
                                Paid
                              </span>
                            ) : invoice?.part_items?.reduce(
                                (partialSum, a) =>
                                  partialSum + parseInt(a.total_value),
                                0
                              ) >
                              paymentHistories?.reduce(
                                (partialSum, a) =>
                                  partialSum + parseInt(a.amount),
                                0
                              ) ? (
                              <span className="badge badge-light-warning">
                                Partial Paid
                              </span>
                            ) : paymentHistories?.reduce(
                                (partialSum, a) =>
                                  partialSum + parseInt(a.amount),
                                0
                              ) == 0 ? (
                              <span className="badge badge-light-danger">
                                {" "}
                                UnPaid
                              </span>
                            ) : (
                              <span className="badge badge-light-danger">
                                {"--"}
                              </span>
                            )}
                          </div>
                        </>
                      ) : (
                        "--"
                      )}
                    </>
                  )}

                  <div className="fw-bolder mt-5">Company</div>
                  <div className="text-gray-600">{invoice?.company?.name}</div>

                  <div className="fw-bolder mt-5">Requisition Type</div>
                  <div className="text-gray-600">
                    {invoice?.quotation?.type
                      ?.replaceAll("_", " ")
                      ?.capitalize() ?? "--"}
                  </div>
                  {invoice?.part_items?.length > 0 && (
                    <>
                      <div className="fw-bolder mt-5">Payment Mode</div>
                      <div className="text-gray-600">
                        {invoice?.payment_mode ?? "--"}
                      </div>

                      <div className="fw-bolder mt-5">Payment Term</div>
                      <div className="text-gray-600">
                        {invoice?.payment_term ?? "--"}
                      </div>

                      <div className="fw-bolder mt-5">Payment Partial Mode</div>
                      <div className="text-gray-600">
                        {invoice?.payment_partial_mode ?? "--"}
                      </div>

                      <div className="fw-bolder mt-5">Sub Total </div>
                      <div className="text-gray-600">
                        {invoice?.sub_total ?? "0"}tk
                      </div>

                      <div className="fw-bolder mt-5">AIT </div>
                      <div className="text-gray-600">
                        {invoice?.ait ?? "0"}% <span className="text-dark">({(invoice?.sub_total*invoice?.ait)/100}) Tk</span>
                      </div>

                      <div className="fw-bolder mt-5">Vat </div>
                      <div className="text-gray-600">
                        {invoice?.vat ?? "0"}% <span className="text-dark">({(invoice?.sub_total*invoice?.vat)/100}) Tk</span>
                      </div>

                      <div className="fw-bolder mt-5">Discount </div>
                      <div className="text-gray-600">
                        {invoice?.discount ?? "0"}% <span className="text-dark">({(invoice?.sub_total*invoice?.discount)/100}) Tk</span>
                      </div>

                      <div className="fw-bolder mt-5">Grand Total</div>
                      <div className="text-gray-600">
                        {invoice?.grand_total ?? "0"}tk
                      </div>

                      <div className="fw-bolder mt-5">VAT/AIT Received </div>
                      <div className="text-gray-600">
                        {invoice?.vat_ait_received ? "Received" : "Not Received"}
                      </div>

                      <div className="fw-bolder mt-5">Next Payment </div>
                      <div className="text-gray-600">
                        {invoice?.next_payment ?? "--"}
                      </div>

                      <div className="fw-bolder mt-5">Last Payment </div>
                      <div className="text-gray-600">
                        {invoice?.last_payment ?? "--"}
                      </div>

                      <div className="fw-bolder mt-5">Priority</div>
                      <div className="text-gray-600">
                        {invoice?.requisition?.priority?.capitalize() ?? "--"}
                      </div>

                      <div className="fw-bolder mt-5">Invoice Date</div>
                      <div className="text-gray-600">
                        <Moment format="D MMMM YYYY">
                          {invoice?.invoice_date}
                        </Moment>
                      </div>

                      <div className="fw-bolder mt-5">Created By</div>
                      <div className="text-gray-600">{invoice?.created_by}</div>
                    </>
                  )}
                </div>
                <div className="card-header">
                  {invoice?.part_items?.length > 0 && (
                    <div className="card-title">
                      <PermissionAbility permission="invoices_print">
                        <h3 className="card-label">
                          <Link
                            className="btn btn-sm btn-dark "
                            to={"/panel/invoices/" + invoice.id + "/print"}
                            style={{ marginRight: "0.75rem" }}
                            target="_blank"
                          >
                            Print
                          </Link>
                        </h3>
                      </PermissionAbility>

                      <PermissionAbility permission="invoices_generate_delivery_note">
                        <h3 className="card-label">
                          <button
                            className="btn btn-sm btn-dark "
                            style={{ marginRight: "0.1rem" }}
                            onClick={() =>
                              navigate(
                                `/panel/delivery-notes/${invoice?.id}/create`
                              )
                            }
                          >
                            Generate Delivery Note
                          </button>
                        </h3>
                      </PermissionAbility>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="col-xl-9">
              <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
                <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-n2">
                  <li className="nav-item">
                    <a
                      className="nav-link text-active-primary pb-4 active"
                      data-bs-toggle="tab"
                      href="#part_items"
                      onClick={() => {
                        setActive("part_items");
                        setTab("part_items");
                      }}
                    >
                      Part Items
                    </a>
                  </li>
                  <PermissionAbility permission="invoices_payment">
                    {invoice?.quotation?.type !== "claim_request" && (
                      <li className="nav-item">
                        <a
                          className="nav-link text-active-primary pb-4"
                          data-bs-toggle="tab"
                          href="#payment_histories"
                          onClick={() => {
                            setActive("payment_histories");
                            setTab("payment_histories");
                          }}
                        >
                          Payment Histories
                        </a>
                      </li>
                    )}
                  </PermissionAbility>

                  <li className="nav-item">
                    <a
                      className={`nav-link text-active-primary pb-4 ${
                        tab == "activities" ? "active" : ""
                      }`}
                      data-bs-toggle="tab"
                      href="#activities"
                      onClick={() => {
                        setActive("payment_histories");
                        setTab("activities");
                      }}
                    >
                      Activities
                    </a>
                  </li>
                </ul>

                <div className="tab-content">
                  {/* Tabs start from here */}
                  <div
                    className={`tab-pane fade ${
                      tab == "part_items" ? "active show" : ""
                    }`}
                    id="part_items"
                    role="tabpanel"
                  >
                    <InvoicePartItems
                      active={active}
                      partItems={invoice?.part_items}
                      sub_total={invoice?.sub_total}
                      ait={invoice?.ait}
                      vat={invoice?.vat}
                      discount={invoice?.discount}
                      grand_total={invoice?.grand_total}
                      type={invoice?.quotation?.type}
                      tab={tab}
                    />
                  </div>
                  <div
                    className={`tab-pane fade ${
                      tab == "payment_histories" ? "active show" : ""
                    }`}
                    id="payment_histories"
                    role="tab-panel"
                  >
                    <PaymentHistores
                      setOpen={setOpen}
                      data={paymentHistories}
                      onClose={getPaymentHistories}
                    />
                  </div>
                  <Activities logName="invoices" modelId={id} tab={tab} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <InvoiceCreatePayment
        open={open}
        onCloseModal={onCloseModal}
        invoice={invoice}
        due={invoice?.grand_total - totalPayment}
      />
    </>
  );
};

export default ShowInvoice;
