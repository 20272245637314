import React, { Fragment, useEffect, useState } from "react";
import Modal from "components/utils/Modal";
import Select from "react-select";
import WareHouseService from "services/WareHouseService";
import BinLocationService from "services/BinLocationService";

const CreateBinLocation = ({ open, onCloseModal, onCreated }) => {
  const [block, setBlock] = useState(false);
  const [warehouses, setWarehouses] = useState([]);

  const [data, setData] = useState({
    warehouse_id: "",
    name: "",
    description: "",
  });

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setBlock(false);

    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSelect = (option, action) => {
    const value = option.value;
    const name = action.name;
    setData({
      ...data,
      [name]: value,
    });
  };

  const addBinLocation = async () => {
    setBlock(true);
    await BinLocationService.create(data);
    onCreated();
    setData({
      warehouse_id: "",
      name: "",
      description: "",
    });
    onCloseModal();
    setBlock(false);
  };

  const getWarehouses = async () => {
    setBlock(false);
    let dt = await WareHouseService.getAll();
    dt = dt.map((itm) => ({ label: itm.name, value: itm.id }));
    setWarehouses(dt);
    setBlock(false);
  };

  useEffect(() => {
    if (open) {
      getWarehouses();
    }
    setBlock(false);
  }, [open]);

  return (
    <div>
      <Modal
        open={open}
        onCloseModal={onCloseModal}
        title={<>Add Bin Location</>}
        body={
          <>
            <div className="form-group">
              <label className="required form-label">Warehouses</label>
              <Select
                options={warehouses}
                onChange={handleSelect}
                name="warehouse_id"
              />
            </div>

            <div className="form-group mt-5">
              <label className="required form-label">Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter  Bin/Location name"
                name="name"
                id="name"
                onChange={handleChange}
              />
            </div>

            <div className="form-group mt-5">
              <label className="form-label">Description</label>
              <textarea
                rows="3"
                type="text"
                className="form-control"
                placeholder="Enter  Description"
                name="description"
                id="description"
                onChange={handleChange}
              />
            </div>

            <button
              type="reset"
              className="btn btn-primary mr-2 mt-5"
              style={{ marginRight: "1rem" }}
              onClick={addBinLocation}
              disabled={block}
            >
              Create
            </button>
            <button
              type="reset"
              className="btn btn-secondary  mt-5 "
              onClick={onCloseModal}
            >
              Cancel
            </button>
          </>
        }
      />
    </div>
  );
};

export default CreateBinLocation;
