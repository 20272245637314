import { useEffect, useState, useRef } from "react";
import Select from "react-select";
import BinLocationService from "services/BinLocationService";
import LcService from "services/LcServices";
import MachineModelService from "services/MachineModelService";
import PartNameService from "services/PartNameService";
import DatePicker from "react-datepicker";
import moment from "moment";


function PartFilter({ enable, onClickOutside, onChange }) {
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target))
        onClickOutside && onClickOutside();
    };

    document.addEventListener("click", handleClickOutside, true);
    return () =>
      document.removeEventListener("click", handleClickOutside, true);
  }, [onClickOutside]);

  const [data, setData] = useState(null);

  const [binLocation, setBinLocation] = useState([]);
  const getBinLocation = async (filter) => {
    let data = await BinLocationService.getAll(filter);
    data = data?.data?.map((itm) => ({ label: itm.name, value: itm.id }));
    setBinLocation(data);
  };

  const [lc, setLc] = useState([]);
  const getLc = async (filter) => {
    let data = await LcService.getAll(filter);
    data = data?.data?.map((itm) => ({ label: itm.invoice_no, value: itm.id }));
    setLc(data);
  };

  const [partName, setPartName] = useState([]);
  const getPartName = async (filters) => {
    let data = await PartNameService.getAll(filters);
    data = data?.data?.map((itm) => ({ label: itm.name, value: itm.id }));
    setPartName(data);
  };

  const [machineModel, setMachineModel] = useState([]);
  const getMachineModel = async (filters) => {
    let data = await MachineModelService.getAllModels(filters);
    data = data?.data?.map((itm) => ({ label: itm.name, value: itm.id }));
    setMachineModel(data);
  };

  useEffect(() => {
    if (enable) {
      getBinLocation();
      getLc();
      getPartName();
      getMachineModel();
    }
  }, [enable]);

  const [defaults, setDefaults] = useState({});

  const handleSelect = (option, action) => {
    const value = option.value;
    const name = action.name;
    setDefaults({ ...defaults, [name]: option });
    setData({ ...data, [name]: value });
  };

  const apply = () => {
    typeof onChange === "function" && onChange(data);
  };

  const [block, setBlock] = useState(false);
  const handleDateSelect = (value, name) => {
    setData({
      ...data,
      ...{
        [name]: new Date(value),
        [name + "_format"]: moment(value).format("YYYY-MM-DD"),
      },
    });
    setBlock(false);
  };

  const reset = () => {
    setData(null);
    typeof onChange === "function" &&
      onChange({
        ...data,
        bin_location_id: null,
        part_name_id: null,
        lc_id: null,
        machine_model_id: null,
      });
  };

  if (!enable) return null;

  let custom = {
    zIndex: 105,
    position: "fixed",
    inset: "0px 0px auto auto",
    margin: 0,
    transform: "translate(-100%, 50%)",
  };

  return (
    <>
      <div
        ref={ref}
        className="menu menu-sub menu-sub-dropdown w-250px w-md-300px show"
        style={custom}
      >
        <div className="px-7 py-5">
          <div className="fs-5 text-dark fw-bolder">Filter Options</div>
        </div>
        <div className="separator border-gray-200"></div>
        <div className="px-7 py-5">
          <div className="mb-10">
            <label className="form-label fw-bold">Part Name:</label>
            <Select
              options={partName}
              onChange={(option, action) => handleSelect(option, action)}
              name="part_name_id"
              defaultValue={defaults?.part_name_id}
            />
          </div>

          <div className="mb-10">
            <label className="form-label fw-bold">LC:</label>
            <Select
              options={lc}
              onChange={(option, action) => handleSelect(option, action)}
              name="lc_id"
              defaultValue={defaults?.lc_id}
            />
          </div>

          <div className="mb-10">
            <label className="form-label fw-bold">Bin/Location:</label>
            <Select
              options={binLocation}
              onChange={(option, action) => handleSelect(option, action)}
              name="bin_location_id"
              defaultValue={defaults?.bin_location_id}
            />
          </div>

          <div className="mb-10">
            <label className="form-label fw-bold">Start Date:</label>
            <DatePicker
              className="form-control"
              selected={data?.start_date}
              onChange={(date) => handleDateSelect(date, "start_date")}
            />
            <input
              type="hidden"
              name="start_date"
              id="start_date"
              value={data?.start_date_format || ""}
            />
            <div
              className="fv-plugins-message-container invalid-feedback"
              htmlFor="start_date"
            ></div>
          </div>
          <div className="mb-10">
            <label className="form-label fw-bold">End Date:</label>
            <DatePicker
              className="form-control"
              selected={data?.end_date}
              onChange={(date) => handleDateSelect(date, "end_date")}
            />
            <input
              type="hidden"
              name="end_date"
              id="end_date"
              value={data?.end_date_format || ""}
            />
            <div
              className="fv-plugins-message-container invalid-feedback"
              htmlFor="end_date"
            ></div>
          </div>

          {/* <div className="mb-10">
            <label className="form-label fw-bold">Machine Model:</label>
            <Select
              options={machineModel}
              onChange={(option, action) => handleSelect(option, action)}
              name="machine_model_id"
              defaultValue={defaults?.machine_model_id}
            />
          </div> */}

          <div className="d-flex justify-content-end">
            <button
              onClick={reset}
              type="button"
              className="btn btn-sm btn-light btn-active-light-primary me-2"
            >
              Reset
            </button>
            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={() => {
                apply();
              }}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default PartFilter;
