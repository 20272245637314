import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import Moment from "react-moment";
import QuotationService from "../../services/QuotationService";
import RequisitionService from "services/RequisitionService";
import { DebounceInput } from "react-debounce-input";
import PartService from "services/PartService";
import { toast } from "react-toastify";
const CreateRequisition = () => {
  let { id } = useParams();
  const navigate = useNavigate();

  const [total, setTotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [quotation, setQuotation] = useState({});
  const getQuotation = async () => {
    let res = await QuotationService.get(id);
    setQuotation(res);
  };
  useEffect(() => {
    if (id) getQuotation();
  }, [id]);

  const [data, setData] = useState(null);
  const [block, setBlock] = useState(false);

  useEffect(() => {
    let totalAmount = 0;
    data?.part_items?.forEach((item) => {
      totalAmount =
        parseInt(totalAmount) +
        parseInt(item?.quantity) * parseInt(item?.unit_value);
    });
    setTotal(totalAmount);
    setGrandTotal(Math.ceil(total *(1 + (data?.ait+data?.vat - data?.discount) / 100)))

  }, [data?.part_items,data?.vat,data?.discount,total,grandTotal,data?.ait]);

  useEffect(() => {
    if (quotation) {
      setData({
        ...data,
        quotation_id: quotation?.id,
        company_id: quotation?.company_id,
        type: quotation?.type,
        ait: 0,
        vat: 0,
        discount: 0,
        grand_total:grandTotal,
        sub_total:total,
        part_items: quotation?.part_items?.map((item) => {
          return {
            id: item?.id,
            part_name: item?.part?.part_name?.name,
            part_number: item?.part?.part_number,
            quantity: item?.quantity,
            unit_value: item?.unit_value,
            remarks: item?.remarks,
            part_id: item?.part_id,
            replace_part_id: null,
            replace_part: null,
          };
        }),
      });
    }
  }, [quotation]);

  useEffect(() => {
    setData({ ...data,grand_total: grandTotal,sub_total: total });
  }, [grandTotal,total]);

  const handleDataChange = (e) => {
    let value 
    if(e.target.name == "vat" || e.target.name == "discount" || e.target.name == "ait"){
      value =  Math.min(100, Number(e.target.value));
    }else{
      value = e.target.value
    }
    setData({ ...data, [e.target.name]: value });
  };
  const handlePartChange = (e, itemId) => {
    let part = data?.part_items.map((item) => {
      if (item?.id == itemId) {
        return { ...item, [e.target.name]: e.target.value };
      } else {
        return item;
      }
    });
    setData({ ...data, part_items: part });
  };
  const handleReplaceChange = (e, itemId) => {
    let part = data?.part_items.map((item) => {
      if (item?.id == itemId) {
        return {
          ...item,
          [e.target[0].name]: e.target[0].value,
          [e.target[1].name]: e.target[1].value,
        };
      } else {
        return item;
      }
    });
    setData({ ...data, part_items: part });
  };

  const increment = (itemId) => {
    let part = data?.part_items.map((item) => {
      if (item?.id == itemId) {
        return { ...item, quantity: parseInt(item.quantity) + 1 };
      } else {
        return item;
      }
    });
    setData({ ...data, part_items: part });
  };

  const decrement = (itemId) => {
    let part = data?.part_items.map((item) => {
      if (item?.id == itemId) {
        return { ...item, quantity: parseInt(item.quantity) - 1 };
      } else {
        return item;
      }
    });
    setData({ ...data, part_items: part });
  };

  const removeItem = (itemId) => {
    let part = data?.part_items.filter((item) => item?.id != itemId);
    setData({ ...data, part_items: part });
  };

  const storeRequisition = async () => {
    setBlock(true);
    let res = await RequisitionService.create(data);
    setBlock(false);
    navigate(`/panel/requisitions`);
  };

  const [searchData, setSearchData] = useState(null);
  const getParts = async (val) => {
    let res = await PartService.getAll({
      q: val.target.value,
    });
    setSearchData(res.data);
  };

  return (
    <div className="post d-flex flex-column-fluid" id="content">
      <div className="container-xxl">
        <div className="card">
          <div className="card-body py-20">
            <div className="mw-lg-950px mx-auto w-100">
              <div className="">
                <table width="100%">
                  <tr>
                    <td>
                      <h6>
                        <strong>Company Name:</strong>
                        <span className="text-muted">
                          {quotation?.company?.name}
                        </span>
                      </h6>

                      <h6>
                        <strong>Group of Company: </strong>
                        <span className="text-muted">
                          {quotation?.company?.company_group}
                        </span>
                      </h6>
                    </td>
                    <td style={{ marginLeft: "120px" }} width="30%"></td>

                    <td style={{ marginLeft: "120px" }}>
                      <h6>
                        <strong>Date: </strong>
                        <span className="text-muted">
                          <Moment format="D MMMM YYYY">
                            {quotation?.created_at}
                          </Moment>
                        </span>
                      </h6>
                      <h6>
                        <strong>Engineer Name: </strong>
                        <span className="text-muted">
                          {quotation?.engineer?.name}
                        </span>
                      </h6>
                      <h6>
                        <strong>Email: </strong>
                        <span className="text-muted">
                          {quotation?.engineer?.email}
                        </span>
                      </h6>
                    </td>
                  </tr>
                </table>

                <div className="d-flex justify-content-between flex-column flex-md-row">
                  <div className="flex-grow-1 pt-8 mb-13">
                    <div className="table-responsive ">
                      <table className="table table-row-bordered align-middle">
                        <thead>
                          <tr className="fs-6 fw-bolder text-dark text-uppercase">
                            <th className="min-w-40px pb-9">SL.No</th>
                            <th className="min-w-120px pb-9 text-end">
                              Parts Name
                            </th>
                            <th className="min-w-80px pb-9 text-end">
                              Parts Number
                            </th>
                            {data?.type == "purchase_request" && (
                              <th className="min-w-80px pb-9 text-end">
                                Unit Price
                              </th>
                            )}
                            <th className="min-w-100px pe-lg-6 pb-9 text-end">
                              Quantity
                            </th>
                            {data?.type == "purchase_request" ? (
                              <th className="min-w-100px pe-lg-6 pb-9 text-end">
                                Total
                              </th>
                            ) : (
                              <>
                                <th className="min-w-150px pe-lg-6 pb-9 text-end">
                                  Replace By
                                </th>
                                <th className="min-w-150px pe-lg-6 pb-9 text-end">
                                  Remarks
                                </th>
                              </>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {data?.part_items?.map((item, index) => (
                            <tr
                              className="fw-bolder text-gray-700 fs-5 text-end"
                              key={index}
                            >
                              <td className="d-flex align-items-center pb-10">
                                {index + 1}
                              </td>
                              <td>{item?.part_name}</td>
                              <td>{item?.part_number}</td>
                              {data?.type == "purchase_request" && (
                                <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    aria-label="Small"
                                    aria-describedby="inputGroup-sizing-sm"
                                    name="unit_value"
                                    placeholder="0TK"
                                    value={item?.unit_value}
                                    onChange={(e) =>
                                      handlePartChange(e, item?.id)
                                    }
                                  />
                                </td>
                              )}

                              <td className="product-quantity">
                                <div className="input-group input-group-sm">
                                  <div className="input-group-prepend">
                                    <span
                                      className="input-group-text"
                                      id="inputGroup-sizing-sm"
                                      onClick={() => {
                                        if (item?.quantity > 0) {
                                          decrement(item?.id);
                                        }
                                      }}
                                    >
                                      <i className="fas fa-minus"></i>
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    className="form-control"
                                    aria-label="Small"
                                    aria-describedby="inputGroup-sizing-sm"
                                    min="1"
                                    value={item?.quantity}
                                    name="quantity"
                                  />

                                  <div className="input-group-prepend">
                                    <span
                                      className="input-group-text"
                                      onClick={() => increment(item?.id)}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <i className="fas fa-plus"></i>
                                    </span>
                                  </div>
                                </div>
                              </td>
                              {quotation?.type == "purchase_request" ? (
                                <td>
                                  {parseInt(item?.unit_value) *
                                    parseInt(item?.quantity)}
                                </td>
                              ) : (
                                <>
                                  <td className="min-w-80px pb-9 text-end">
                                    <div className="col-lg-12">
                                      <div className="form-group">
                                        <DebounceInput
                                          className="form-control"
                                          placeholder="search"
                                          debounceTimeout={300}
                                          onChange={getParts}
                                          value={item?.replace_part}
                                        />

                                        <div>
                                          {searchData?.length > 0 ? (
                                            <div className="card border border-secondary ">
                                              <div className="card-body ">
                                                {searchData?.map(
                                                  (itm, index) => (
                                                    <div key={index}>
                                                      <Link
                                                        to={itm?.id}
                                                        style={{
                                                          color: "black",
                                                        }}
                                                        onClick={() => {
                                                          handleReplaceChange(
                                                            {
                                                              target: [
                                                                {
                                                                  name: "replace_part",
                                                                  value:
                                                                    itm
                                                                      ?.part_name
                                                                      ?.name +
                                                                    "(" +
                                                                    itm
                                                                      ?.part_name
                                                                      ?.part_number +
                                                                    ")",
                                                                },
                                                                {
                                                                  name: "replace_part_id",
                                                                  value:
                                                                    itm?.id,
                                                                },
                                                              ],
                                                            },
                                                            item?.id
                                                          );
                                                          setSearchData(null);
                                                        }}
                                                      >
                                                        <p>
                                                          {itm?.part_name?.name}
                                                          <span>
                                                            (
                                                            {
                                                              itm?.part_name
                                                                ?.part_number
                                                            }
                                                            )
                                                          </span>
                                                          <span>
                                                            ({itm?.machine_model?.name})
                                                          </span>
                                                          <span>
                                                            ({itm?.qty})
                                                          </span>
                                                        </p>
                                                      </Link>
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="min-w-80px pb-9 text-end">
                                    <input
                                      type="text"
                                      className="form-control"
                                      aria-label="Small"
                                      aria-describedby="inputGroup-sizing-sm"
                                      name="remarks"
                                      value={item?.remarks}
                                      onChange={(e) =>
                                        handlePartChange(e, item?.id)
                                      }
                                    />
                                  </td>
                                </>
                              )}
                              <td className="text-end">
                                {data?.part_items.length > 1 && (
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-icon btn-danger"
                                    data-kt-element="remove-item"
                                    onClick={() => removeItem(item?.id)}
                                  >
                                    <i className="fa fa-trash"></i>
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                          {data?.type == "purchase_request" && (
                            <>
                              <tr className="fw-bolder text-gray-700 fs-5 text-end">
                                <td colSpan={3}></td>
                                <td>Sub-total</td>
                                <td></td>
                                <td>{total}</td>
                                <td></td>
                              </tr>
                              <tr className="fw-bolder text-gray-700 fs-5 text-end">
                                <td colSpan={3}></td>
                                <td className="align-center justify-content-center">
                                  AIT(%)
                                </td>
                                <td className="">
                                  <input
                                    type="number"
                                    pattern="[0-99]*"
                                    className="form-control"
                                    aria-label="Small"
                                    aria-describedby="inputGroup-sizing-sm"
                                    name="ait"
                                    value={data?.ait}
                                    onChange={handleDataChange}
                                  />
                                </td>
                                <td>{(total * data?.ait) / 100}</td>
                                <td></td>
                              </tr>
                              <tr className="fw-bolder text-gray-700 fs-5 text-end">
                                <td colSpan={3}></td>
                                <td className="align-center justify-content-center">
                                  Vat(%)
                                </td>
                                <td className="">
                                  <input
                                    type="number"
                                    pattern="[0-99]*"
                                    className="form-control"
                                    aria-label="Small"
                                    aria-describedby="inputGroup-sizing-sm"
                                    name="vat"
                                    value={data?.vat}
                                    onChange={handleDataChange}
                                    // onChange={(e) => {
                                    //   if(e.target.value.length >99) {
                                    //     toast.error("Amount should not be grater then 100 amount!");
                                    //   }
                                    //   // else{
                                    //   //   return handleDataChange
                                    //   // }
                                    // }}
                                  />
                                </td>
                                <td>{(total * data?.vat) / 100}</td>
                                <td></td>
                              </tr>
                              <tr className="fw-bolder text-gray-700 fs-5 text-end">
                                <td colSpan={3}></td>
                                <td>Discount(%)</td>
                                <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    aria-label="Small"
                                    aria-describedby="inputGroup-sizing-sm"
                                    name="discount"
                                    placeholder=""
                                    value={data?.discount}
                                    onChange={handleDataChange}
                                  />
                                </td>
                                <td>{(total * data?.discount) / 100}</td>
                                <td></td>
                              </tr>
                              <tr className="fw-bolder text-gray-700 fs-5 text-end">
                                <td colSpan={3}></td>
                                <td>Grand Total</td>
                                <td></td>
                                <td>
                                  {grandTotal}
                                </td>
                                <td></td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                      <div className="separator separator-dashed"></div>
                      <button
                        className="btn btn-primary mt-5"
                        onClick={() => {
                          storeRequisition();
                        }}
                        disabled={block}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateRequisition;
