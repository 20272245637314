import Modal from "components/utils/Modal";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PartService from "services/PartService";
import QuotationService from "services/QuotationService";
toast.configure();

const UpdatePartInfo = ({ open, data, onCloseModal, onUpdated }) => {
  const [updateData, setUpdateData] = useState(null);

  useEffect(() => {
    setUpdateData({
      unit_price: data?.unit_price,
      remarks: data?.remarks,
    });
  }, [data]);

  const updateInfo = async () => {
    await PartService.updatePartInfo(data?.id, updateData); 
    onUpdated();
    onCloseModal();
  };

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setUpdateData({
      ...updateData,
      [name]: value,
    });
  };

  return (
    <div>
      <Modal
        open={open}
        onCloseModal={onCloseModal}
        title={<>Update Info</>}
        body={
          <>
            <form id="update-part-info">
              

              <div className="mb-5 fv-row fv-plugins-icon-container">
                <label className="form-label">Selling Price</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Selling Price"
                  name="unit_price"
                  id="unit_price"
                  value={updateData?.unit_price ?? ""}
                  onChange={handleChange}
                />
                <div
                  className="fv-plugins-message-container invalid-feedback"
                  htmlFor="unit_price"
                ></div>
              </div>

              <div className="mb-5 fv-row fv-plugins-icon-container">
                <label className="form-label">Remarks</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Remarks"
                  name="remarks"
                  id="remarks"
                  value={updateData?.remarks ?? ""}
                  onChange={handleChange}
                />
                <div
                  className="fv-plugins-message-container invalid-feedback"
                  htmlFor="remarks"
                ></div>
              </div>

              <button
                type="reset"
                className="btn btn-primary mr-2 mt-5"
                style={{ marginRight: "1rem" }}
                onClick={updateInfo}
              >
                Update
              </button>
              <button
                type="reset"
                className="btn btn-secondary  mt-5 "
                onClick={onCloseModal}
              >
                Cancel
              </button>
            </form>
          </>
        }
      />
    </div>
  );
};

export default UpdatePartInfo;
