import Tags from "components/utils/Tags";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import SettingsService from "services/SettingsService";

const Settings = () => {
  const [roles, setRoles] = useState([]);
  const [value, setValue] = useState([]);
  const [block, setBlock] = useState(false);
  const [data, setData] = useState({});

  const [logo, setLogo] = useState([]);
  const [icon, setIcon] = useState([]);

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSelect = (option, conf) => {
    let value = option.value;

    if (Array.isArray(option))
      value = option.map((dt) => {
        return dt.value;
      });
    const name = conf.name;
    setBlock(false);

    setData({
      ...data,
      [name]: value,
    });
  };

  const handleLogo = (e) => {
    setLogo({ logo: e.target.files[0] });
    let logoShow = document.getElementById("logo");
    let fr = new FileReader();
    fr.readAsDataURL(e.target.files[0]);

    fr.addEventListener("load", function () {
      logoShow.style.backgroundImage = "url(" + this.result + ")";
    });
  };

  const handleIcon = (e) => {
    setIcon({ icon: e.target.files[0] });
    let IconShow = document.getElementById("icon");
    let fr = new FileReader();
    fr.readAsDataURL(e.target.files[0]);

    fr.addEventListener("load", function () {
      IconShow.style.backgroundImage = "url(" + this.result + ")";
    });
  };

  //get notifiable users
  const getNotifiableUsers = async () => {
    let dt = await SettingsService.getUsers();
    dt = dt.map((itm) => ({ label: itm?.name, value: itm?.id }));
    setRoles(dt);
  };

  //Store data
  const createSettings = (e) => {
    e.preventDefault();
    setBlock(true);
    const formData = new FormData(document.getElementById("settings"));
    formData.append("notifiable_users", data.notifiable_users);
    SettingsService.create(formData);
    setBlock(false);
  };

  // get setting
  const getSettings = async () => {
    const res = await SettingsService.getAll();
    setData(res.data);
  };

  useEffect(() => {
    getNotifiableUsers();
    getSettings();
  }, []);

  return (
    <div
      className="content d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <div className="post d-flex flex-column-fluid" id="kt_post">
        <div id="kt_content_container" className="container-xxl">
          <div className="card">
            <div className="card-header">
              <div className="card-title fs-3 fw-bolder">Project Settings</div>
            </div>

            <form
              onSubmit={createSettings}
              id="settings"
              encType="multipart/form-data"
            >
              {/* <form
              action=""
              id="settings"
              encType="multipart/form-data"
            > */}
              <div className="card-body p-9">
                {/* for project logo */}
                {/* <div className="row mb-5">
                  <div className="col-xl-3">
                    <div className="fs-6 fw-bold mt-2 mb-3">Logo</div>
                  </div>

                  <div className="col-lg-8">
                    <div
                      className="image-input image-input-outline"
                      data-kt-image-input="true"
                      style={{
                        backgroundImage:
                          "url('assets/media/svg/avatars/blank.svg')",
                      }}
                    >
                      <div
                        id="logo"
                        className="image-input-wrapper w-125px h-125px bgi-position-center"
                        style={{
                          backgroundSize: "75%",
                          backgroundImage: `url('${data?.logo}')`,
                        }}
                      ></div>

                      <label
                        className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                        data-kt-image-input-action="change"
                        data-bs-toggle="tooltip"
                        title="Change avatar"
                      >
                        <i className="bi bi-pencil-fill fs-7"></i>

                        <input
                          type="file"
                          name="logo"
                          accept=".png, .jpg, .jpeg"
                          onChange={handleLogo}
                        />
                        <input type="hidden" name="logo" />
                      </label>

                      <span
                        className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                        data-kt-image-input-action="cancel"
                        data-bs-toggle="tooltip"
                        title="Cancel avatar"
                      >
                        <i className="bi bi-x fs-2"></i>
                      </span>

                      <span
                        className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                        data-kt-image-input-action="remove"
                        data-bs-toggle="tooltip"
                        title="Remove avatar"
                      >
                        <i className="bi bi-x fs-2"></i>
                      </span>
                    </div>

                    <div className="form-text">
                      Allowed file types: png, jpg, jpeg.
                    </div>
                  </div>
                </div> */}

                {/* for project tab icon */}
                {/* <div className="row mb-5">
                  <div className="col-xl-3">
                    <div className="fs-6 fw-bold mt-2 mb-3">Icon</div>
                  </div>

                  <div className="col-lg-8">
                    <div
                      id="icon"
                      className="image-input image-input-outline"
                      data-kt-image-input="true"
                      style={{
                        backgroundImage:
                          "url('assets/media/svg/avatars/blank.svg')",
                      }}
                    >
                      <div
                        className="image-input-wrapper w-125px h-125px bgi-position-center"
                        style={{
                          backgroundSize: "75%",
                          backgroundImage: `url('${data?.icon}')`,
                        }}
                      ></div>

                      <label
                        className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                        data-kt-image-input-action="change"
                        data-bs-toggle="tooltip"
                        title="Change avatar"
                      >
                        <i className="bi bi-pencil-fill fs-7"></i>

                        <input
                          type="file"
                          name="icon"
                          accept=".png, .jpg, .jpeg"
                          onChange={handleIcon}
                        />
                        <input type="hidden" name="icon" />
                      </label>

                      <span
                        className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                        data-kt-image-input-action="cancel"
                        data-bs-toggle="tooltip"
                        title="Cancel avatar"
                      >
                        <i className="bi bi-x fs-2"></i>
                      </span>

                      <span
                        className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                        data-kt-image-input-action="remove"
                        data-bs-toggle="tooltip"
                        title="Remove avatar"
                      >
                        <i className="bi bi-x fs-2"></i>
                      </span>
                    </div>

                    <div className="form-text">
                      Allowed file types: png, jpg, jpeg.
                    </div>
                  </div>
                </div> */}

                {/* <div className="row mb-8">
                  <div className="col-xl-3">
                    <div className="fs-6 fw-bold mt-2 mb-3">Site Name</div>
                  </div>

                  <div className="col-xl-9 fv-row">
                    <input
                      type="text"
                      className="form-control form-control-solid"
                      name="site_name"
                      value={data?.site_name ?? ""}
                      onChange={handleChange}
                    />
                  </div>
                </div> */}

                <div className="row mb-8">
                  <div className="col-xl-3">
                    <div className="fs-6 fw-bold mt-2 mb-3">
                      Notifiable Users
                    </div>
                  </div>

                  <div className="col-xl-9 fv-row">
                    <Select
                      value={roles.filter((option) =>
                        data?.notifiable_users?.includes(option.value)
                      )}
                      isMulti
                      options={roles}
                      onChange={handleSelect}
                      name="notifiable_users"
                    />
                  </div>
                </div>

                <div className="row mb-8">
                  <div className="col-xl-3">
                    <div className="fs-6 fw-bold mt-2 mb-3">
                      Notifiable Emails
                    </div>
                  </div>

                  <div className="col-xl-9 fv-row">
                    <Tags
                      placeholder="Enter notifiable emails"
                      name="notifiable_emails"
                      id="notifiable_emails"
                      value={data.notifiable_emails ?? ""}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              {/* </form> */}
              <div className="card-footer d-flex justify-content-end py-6 px-9">
                <button
                  type="reset"
                  className="btn btn-light btn-active-light-primary me-2"
                >
                  Discard
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  id="kt_project_settings_submit"
                  onClick={createSettings}
                  // onClick={() => {
                  //   createSettings();
                  // }}
                >
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
