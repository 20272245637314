import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import Moment from "react-moment";
import Confirmation from "components/utils/Confirmation";
import InvoiceService from "services/InvoiceService";
import { Activities } from "components/utils/Activities";
import PermissionAbility from "helpers/PermissionAbility";
import NewDropzone from "./Dropzone/MyDropzone";
import RequisitionService from "services/RequisitionService";
import { DebounceInput } from "react-debounce-input";
import PartService from "services/PartService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ShowRequisition = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [tab, setTab] = useState("requisitions");
  const [grandTotal, setGrandTotal] = useState(0);

  const [requisition, setRequisition] = useState(null);
  const getRequisition = async () => {
    let res = await RequisitionService.get(id);
    setRequisition(res);
  };
  useEffect(() => {
    if (id) getRequisition();
  }, [id]);

  const [block, setBlock] = useState(true);
  const [data, setData] = useState();

  useEffect(() => {
    if (requisition) {
      setData({
        ...data,
        locked: requisition?.locked_at ? true : false,
        requisition_id: requisition?.id,
        company_id: requisition?.company?.id,
        type: requisition?.quotation?.type,
        ait: requisition?.ait,
        vat: requisition?.vat,
        discount: requisition?.discount,
        sub_total: requisition?.sub_total,
        grand_total: requisition?.grand_total,
        part_items: requisition?.part_items?.map((item) => {
          return {
            id: item?.id,
            part_name: item?.part?.part_name?.name,
            part_number: item?.part?.part_name?.part_number,
            quantity: item?.quantity,
            unit_value: item?.unit_value,
            remarks: item?.remarks,
            part_id: item?.part_id,
            replace_part_id: item?.replace_part_id,
            replace_part:
              item?.replace_part?.part_name?.name +
              "(" +
              item?.replace_part?.part_number +
              ")",
          };
        }),
      });
    }
  }, [requisition]);

  const [total, setTotal] = useState(0);
  useEffect(() => {
    let totalAmount = 0;
    data?.part_items?.forEach((item) => {
      totalAmount =
        parseInt(totalAmount) +
        parseInt(item?.quantity) * parseInt(item?.unit_value);
    });
    setTotal(totalAmount);
    setGrandTotal(Math.ceil(total * (1 + (data?.ait + data?.vat - data?.discount) / 100)));
  }, [data?.part_items, data?.vat,data?.ait, data?.discount, total, grandTotal]);

  useEffect(() => {
    setData({
      ...data,
      grand_total: grandTotal,
      sub_total: total,
    }); //add part_items and total amount in data
  }, [data?.part_items, grandTotal, total]);

  const handleDataChange = (e) => {
    let value;
    if (e.target.name == "vat" || e.target.name == "discount"|| e.target.name == "ait") {
      setBlock(false);
      value = Math.min(100, Number(e.target.value));
    } else {
      value = e.target.value;
    }
    setData({ ...data, [e.target.name]: value });
  };
  const handlePartChange = (e, itemId) => {
    let part = data?.part_items.map((item) => {
      if (item?.id == itemId) {
        return { ...item, [e.target.name]: e.target.value };
      } else {
        return item;
      }
    });
    setData({ ...data, part_items: part });
  };
  const handleReplaceChange = (e, itemId) => {
    let part = data?.part_items.map((item) => {
      if (item?.id == itemId) {
        return {
          ...item,
          [e.target[0].name]: e.target[0].value,
          [e.target[1].name]: e.target[1].value,
        };
      } else {
        return item;
      }
    });
    setData({ ...data, part_items: part });
  };

  const increment = (itemId) => {
    let part = data?.part_items.map((item) => {
      if (item?.id == itemId) {
        return { ...item, quantity: parseInt(item.quantity) + 1 };
      } else {
        return item;
      }
    });
    setData({ ...data, part_items: part });
  };

  const decrement = (itemId) => {
    let part = data?.part_items.map((item) => {
      if (item?.id == itemId) {
        return { ...item, quantity: parseInt(item.quantity) - 1 };
      } else {
        return item;
      }
    });
    setData({ ...data, part_items: part });
  };

  const removeItem = (itemId) => {
    let part = data?.part_items.filter((item) => item?.id != itemId);
    setData({ ...data, part_items: part });
  };

  const [searchData, setSearchData] = useState(null);
  const getParts = async (val) => {
    let res = await PartService.getAll({
      q: val.target.value,
    });
    setSearchData(res.data);
  };

  const lockedPartItems = async () => {
    setBlock(true);
    if (file.data.length > 0 || data?.type == "claim_request") {
      await RequisitionService.locked(data);
      getRequisition();
    } else {
      toast.warning("Please attched Purchase Order!");
      setTab("files");
    }
    setBlock(false);
  };

  const approveRequisition = async () => {
    setBlock(true);
    await RequisitionService.approve(id);
    getRequisition();
    setBlock(false);
  };

  const rejectRequisition = async () => {
    setBlock(true);
    await RequisitionService.reject(id);
    getRequisition();
    setBlock(false);
  };

  //File API

  const [uuid, setuuid] = useState();
  const [model_id, setModelId] = useState();
  const [file, setFile] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const uploadFile = async (formData) => {
    await RequisitionService.fileUpload(id, formData);
    getFile();
  };
  const deleteItem = async () => {
    await RequisitionService.deleteFile(uuid, model_id);
    getFile();
  };
  const getFile = async () => {
    const res = await RequisitionService.getFile(id);
    setFile(res);
  };

  useEffect(() => {
    if (id) getFile();
  }, [id]);

  const updateRequisition = async () => {
    await RequisitionService.update(id, data);
    setBlock(true);
    getRequisition();
  };

  //* Generating Invoice
  const storeInvoice = async () => {
    setBlock(true);
    let res = await InvoiceService.create(requisition);
    setBlock(false);
    navigate(`/panel/invoices/${res.data?.id}`);
  };

  const permissions = JSON.parse(localStorage.getItem("user")).user.permissions;

  return (
    <>
      <div className="d-flex flex-column-fluid">
        <div className="container">
          <div className="row">
            <div className="col-xl-3">
              <div className="card card-custom">
                <div className="card-header">
                  <div className="card-title">
                    <h3 className="card-label">
                      <button
                        className="btn btn-sm btn-dark "
                        style={{ marginRight: "0.75rem" }}
                        onClick={() => navigate(-1)}
                      >
                        <i className="fa fa-arrow-left"></i>Back
                      </button>
                      Details
                    </h3>
                  </div>
                </div>

                <div className="card-body py-4">
                  <div className="fw-bolder mt-5">PQ Number</div>
                  <div className="text-gray-600">{requisition?.rq_number}</div>

                  <div className="fw-bolder mt-5">Company</div>
                  <div className="text-gray-600">
                    {requisition?.company?.name}
                  </div>

                  <div className="fw-bolder mt-5">Machines</div>

                  <div className="fw-bolder mt-5">Expected Delivery</div>
                  <div className="text-gray-600">
                    <Moment format="D MMMM YYYY">
                      {requisition?.quotation?.expected_delivery}
                    </Moment>
                  </div>

                  <div className="fw-bolder mt-5">Payment Mode</div>
                  <div className="text-gray-600">
                    {requisition?.payment_mode ?? "--"}
                  </div>

                  <div className="fw-bolder mt-5">Priority</div>
                  <div className="text-gray-600">
                    {requisition?.quotation?.priority?.capitalize()}
                  </div>

                  <div className="fw-bolder mt-5">Type</div>
                  <div className="text-gray-600">
                    {requisition?.quotation?.type
                      ?.replaceAll("_", " ")
                      ?.capitalize()}
                  </div>

                  <div className="fw-bolder mt-5">Updated At</div>
                  <div className="text-gray-600">
                    <Moment format="D MMMM YYYY">
                      {requisition?.quotation?.updated_at ?? "--"}
                    </Moment>
                  </div>

                  <div className="fw-bolder mt-5">Ref Number</div>
                  <div className="text-gray-600">
                    {requisition?.quotation?.ref_number ?? "--"}
                  </div>

                  <div className="fw-bolder mt-5">Reason Of Trouble</div>
                  <div className="text-gray-600">
                    {requisition?.quotation?.reason_of_trouble ?? "--"}
                  </div>

                  <div className="fw-bolder mt-5">Solutions</div>
                  <div className="text-gray-600">
                    {requisition?.quotation?.solutions ?? "--"}
                  </div>

                  <div className="fw-bolder mt-5">Remarks</div>
                  <div className="text-gray-600">
                    {requisition?.quotation?.remarks ?? "--"}
                  </div>

                  <div className="fw-bolder mt-5">Sub Total </div>
                  <div className="text-gray-600">
                    {requisition?.sub_total ?? "0"}tk
                  </div>

                  <div className="fw-bolder mt-5">AIT </div>
                  <div className="text-gray-600">
                    {requisition?.ait ?? "0"}% <span className="text-dark">({(requisition?.sub_total*requisition?.ait)/100}) Tk</span>
                  </div>

                  <div className="fw-bolder mt-5">Vat </div>
                  <div className="text-gray-600">
                    {requisition?.vat ?? "0"}% <span className="text-dark">({(requisition?.sub_total*requisition?.vat)/100}) Tk</span>
                  </div>

                  <div className="fw-bolder mt-5">Discount </div>
                  <div className="text-gray-600">
                    {requisition?.discount ?? "0"}% <span className="text-dark">({(requisition?.sub_total*requisition?.discount)/100}) Tk</span>
                  </div>

                  <div className="fw-bolder mt-5">Grand Total</div>
                  <div className="text-gray-600">
                    {requisition?.grand_total ?? "0"}tk
                  </div>

                  <div className="fw-bolder mt-5">Created At</div>
                  <div className="text-gray-600">
                    <Moment format="D MMMM YYYY">
                      {requisition?.created_at}
                    </Moment>
                  </div>

                  <div className="fw-bolder mt-5">Created By</div>
                  <div className="text-gray-600">
                    {requisition?.user?.name
                      ?.replaceAll("_", " ")
                      ?.capitalize() ?? "--"}
                  </div>

                  {!permissions.includes("requisitions_approve") &&
                    requisition?.status == "pending" && (
                      <div className="fw-bolder mt-5 badge-lg badge badge-warning">
                        Waitting for Approval
                      </div>
                    )}
                </div>
                <div className="card-header">
                  <div className="card-title">
                    <h3 className="card-label">
                      <PermissionAbility permission="requisitions_print">
                        <Link
                          className="btn btn-sm btn-dark "
                          to={
                            "/panel/requisitions/" + requisition?.id + "/print"
                          }
                          style={{ marginRight: "0.75rem" }}
                          target="_blank"
                        >
                          Print
                        </Link>
                      </PermissionAbility>
                    </h3>
                    {requisition?.status != "pending" && (
                      <h3 className="card-label">
                        <PermissionAbility permission="requisitions_generate_invoice">
                          <button
                            className="btn btn-sm btn-dark "
                            style={{ marginRight: "0.1rem" }}
                            onClick={() => {
                              storeInvoice();
                            }}
                          >
                            Generate Invoice
                          </button>
                        </PermissionAbility>
                      </h3>
                    )}

                    {requisition?.status == "approved" && (
                      <PermissionAbility permission="requisitions_lock">
                        {data?.locked ? (
                          <h3>
                            <button
                              className="btn btn-sm btn-danger float-end fs-6 "
                              onClick={lockedPartItems}
                            >
                              Locked
                            </button>
                          </h3>
                        ) : (
                          <h3>
                            <button
                              className="btn btn-sm btn-dark float-end fs-6 "
                              onClick={lockedPartItems}
                            >
                              Lock
                            </button>
                          </h3>
                        )}
                      </PermissionAbility>
                    )}
                    <PermissionAbility permission="requisitions_approve">
                      {requisition?.status == "pending" && (
                        <>
                          <h3 className="card-label">
                            <button
                              className="btn btn-sm btn-success "
                              style={{ marginRight: "0.1rem" }}
                              onClick={() => {
                                approveRequisition();
                              }}
                            >
                              Approve
                            </button>
                          </h3>
                          <h3 className="card-label">
                            <button
                              className="btn btn-sm btn-danger "
                              style={{ marginRight: "0.1rem" }}
                              onClick={() => {
                                rejectRequisition();
                              }}
                            >
                              Reject
                            </button>
                          </h3>
                        </>
                      )}
                    </PermissionAbility>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-9">
              <div className="flex-lg-row-fluid ms-lg-15">
                <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8">
                  <li className="nav-item">
                    <a
                      className={`nav-link text-active-primary pb-4 ${
                        tab == "requisitions" ? "active" : ""
                      }`}
                      data-bs-toggle="tab"
                      href="#quotations"
                      onClick={() => setTab("requisitions")}
                    >
                      Part Items
                    </a>
                  </li>

                  <PermissionAbility permission="requisitions_files">
                    {data?.type == "purchase_request" && (
                      <li className="nav-item">
                        <a
                          className={`nav-link text-active-primary pb-4 ${
                            tab == "files" ? "active" : ""
                          }`}
                          data-bs-toggle="tab"
                          href="#files"
                          onClick={() => setTab("files")}
                        >
                          Purchase Order
                        </a>
                      </li>
                    )}
                  </PermissionAbility>

                  <li className="nav-item">
                    <a
                      className={`nav-link text-active-primary pb-4 ${
                        tab == "activities" ? "active" : ""
                      }`}
                      data-bs-toggle="tab"
                      href="#activities"
                      onClick={() => setTab("activities")}
                    >
                      Activities
                    </a>
                  </li>
                </ul>
              </div>
              <div className="tab-content">
                <div
                  className={`tab-pane fade ${
                    tab == "requisitions" ? "active show" : ""
                  }`}
                  id="requisitions"
                  role="tabpanel"
                >
                  <div className="card card-custom gutter-b">
                    <div className="card-body px-0">
                      <div className="card mb-5 mb-xl-8">
                        <div className="card-body py-3">
                          <div className="table-responsive">
                            <table className="table table-row-bordered align-middle">
                              <thead>
                                <tr className="fs-6 fw-bolder text-dark text-uppercase">
                                  <th className="min-w-40px pb-9">SL.No</th>
                                  <th className="min-w-120px pb-9 text-end">
                                    Part Name
                                  </th>
                                  <th className="min-w-80px pb-9 text-end">
                                    Part Number
                                  </th>
                                  {data?.type == "purchase_request" && (
                                    <th className="min-w-80px pb-9 text-end">
                                      Unit Price
                                    </th>
                                  )}
                                  <th className="min-w-100px pe-lg-6 pb-9 text-end">
                                    Quantity
                                  </th>
                                  {data?.type == "purchase_request" ? (
                                    <th className="min-w-100px pe-lg-6 pb-9 text-end">
                                      Total
                                    </th>
                                  ) : (
                                    <>
                                      <th className="min-w-150px pe-lg-6 pb-9 text-end">
                                        Replace By
                                      </th>
                                      <th className="min-w-150px pe-lg-6 pb-9 text-end">
                                        Remarks
                                      </th>
                                    </>
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                {data?.part_items?.map((item, index) => (
                                  <tr
                                    className="fw-bolder text-gray-700 fs-5 text-end"
                                    key={index}
                                  >
                                    <td className="d-flex align-items-center pb-10">
                                      {index + 1}
                                    </td>
                                    <td>{item?.part_name}</td>
                                    <td>{item?.part_number}</td>
                                    {data?.type == "purchase_request" && (
                                      <td>
                                        <input
                                          type="number"
                                          className="form-control"
                                          aria-label="Small"
                                          aria-describedby="inputGroup-sizing-sm"
                                          name="unit_value"
                                          placeholder="0TK"
                                          value={item?.unit_value}
                                          onChange={(e) =>
                                            handlePartChange(e, item?.id)
                                          }
                                        />
                                      </td>
                                    )}

                                    <td className="product-quantity">
                                      <div className="input-group input-group-sm">
                                        <div className="input-group-prepend">
                                          <span
                                            className="input-group-text"
                                            id="inputGroup-sizing-sm"
                                            onClick={() => {
                                              if (item?.quantity > 0) {
                                                decrement(item?.id);
                                              }
                                            }}
                                          >
                                            <i className="fas fa-minus"></i>
                                          </span>
                                        </div>
                                        <input
                                          type="text"
                                          className="form-control"
                                          aria-label="Small"
                                          aria-describedby="inputGroup-sizing-sm"
                                          min="1"
                                          value={item?.quantity}
                                          name="quantity"
                                        />

                                        <div className="input-group-prepend">
                                          <span
                                            className="input-group-text"
                                            onClick={() => increment(item?.id)}
                                            style={{ cursor: "pointer" }}
                                          >
                                            <i className="fas fa-plus"></i>
                                          </span>
                                        </div>
                                      </div>
                                    </td>
                                    {data?.type == "purchase_request" ? (
                                      <td>
                                        {parseInt(item?.unit_value) *
                                          parseInt(item?.quantity)}
                                      </td>
                                    ) : (
                                      <>
                                        <td className="min-w-80px pb-9 text-end">
                                          <div className="col-lg-12">
                                            <div className="form-group">
                                              <DebounceInput
                                                className="form-control"
                                                placeholder="search"
                                                debounceTimeout={300}
                                                onChange={getParts}
                                                value={item?.replace_part}
                                              />

                                              <div>
                                                {searchData?.length > 0 ? (
                                                  <div className="card border border-secondary ">
                                                    <div className="card-body ">
                                                      {searchData?.map(
                                                        (itm, index) => (
                                                          <div key={index}>
                                                            <Link
                                                              to={itm?.id}
                                                              style={{
                                                                color: "black",
                                                              }}
                                                              onClick={() => {
                                                                handleReplaceChange(
                                                                  {
                                                                    target: [
                                                                      {
                                                                        name: "replace_part",
                                                                        value:
                                                                          itm
                                                                            ?.part_name
                                                                            ?.name +
                                                                          "(" +
                                                                          itm?.part_name?.part_number +
                                                                          ")",
                                                                      },
                                                                      {
                                                                        name: "replace_part_id",
                                                                        value:
                                                                          itm?.id,
                                                                      },
                                                                    ],
                                                                  },
                                                                  item?.id
                                                                );
                                                                setSearchData(
                                                                  null
                                                                );
                                                              }}
                                                            >
                                                              <p>
                                                                {
                                                                  itm?.part_name
                                                                    ?.name
                                                                }
                                                                <span>
                                                                  (
                                                                  {
                                                                    itm?.part_name?.part_number
                                                                  }
                                                                  )
                                                                </span>
                                                                <span>
                                                                  ({itm?.qty})
                                                                </span>
                                                              </p>
                                                            </Link>
                                                          </div>
                                                        )
                                                      )}
                                                    </div>
                                                  </div>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                        <td className="min-w-80px pb-9 text-end">
                                          <input
                                            type="text"
                                            className="form-control"
                                            aria-label="Small"
                                            aria-describedby="inputGroup-sizing-sm"
                                            name="remarks"
                                            value={item?.remarks}
                                            onChange={(e) =>
                                              handlePartChange(e, item?.id)
                                            }
                                          />
                                        </td>
                                      </>
                                    )}
                                    <td className="text-end">
                                      {data?.part_items.length > 1 && (
                                        <button
                                          type="button"
                                          className="btn btn-sm btn-icon btn-danger"
                                          data-kt-element="remove-item"
                                          onClick={() => removeItem(item?.id)}
                                        >
                                          <i className="fa fa-trash"></i>
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                                {data?.type == "purchase_request" && (
                                  <>
                                    <tr className="fw-bolder text-gray-700 fs-5 text-end">
                                      <td colSpan={3}></td>
                                      <td>Sub-total</td>
                                      <td></td>
                                      <td>{total}</td>
                                      <td></td>
                                    </tr>
                                    <tr className="fw-bolder text-gray-700 fs-5 text-end">
                                      <td colSpan={3}></td>
                                      <td className="align-center justify-content-center">
                                        AIT(%)
                                      </td>
                                      <td className="">
                                        <input
                                          type="number"
                                          className="form-control"
                                          aria-label="Small"
                                          aria-describedby="inputGroup-sizing-sm"
                                          name="ait"
                                          value={data?.ait}
                                          onChange={handleDataChange}
                                        />
                                      </td>
                                      <td>{(total * data?.ait) / 100}</td>
                                      <td></td>
                                    </tr>
                                    <tr className="fw-bolder text-gray-700 fs-5 text-end">
                                      <td colSpan={3}></td>
                                      <td className="align-center justify-content-center">
                                        Vat(%)
                                      </td>
                                      <td className="">
                                        <input
                                          type="number"
                                          className="form-control"
                                          aria-label="Small"
                                          aria-describedby="inputGroup-sizing-sm"
                                          name="vat"
                                          value={data?.vat}
                                          onChange={handleDataChange}
                                        />
                                      </td>
                                      <td>{(total * data?.vat) / 100}</td>
                                      <td></td>
                                    </tr>
                                    <tr className="fw-bolder text-gray-700 fs-5 text-end">
                                      <td colSpan={3}></td>
                                      <td>Discount(%)</td>
                                      <td>
                                        <input
                                          type="number"
                                          className="form-control"
                                          aria-label="Small"
                                          aria-describedby="inputGroup-sizing-sm"
                                          name="discount"
                                          placeholder=""
                                          value={data?.discount}
                                          onChange={handleDataChange}
                                        />
                                      </td>
                                      <td className="text-danger">
                                        {(total * data?.discount) / 100}
                                      </td>
                                      <td></td>
                                    </tr>
                                    <tr className="fw-bolder text-gray-700 fs-5 text-end">
                                      <td colSpan={3}></td>
                                      <td>Grand Total</td>
                                      <td></td>
                                      <td>{grandTotal}</td>
                                      <td></td>
                                    </tr>
                                  </>
                                )}
                              </tbody>
                            </table>
                            <PermissionAbility permission="requisitions_partItems_update">
                              {!data?.locked && (
                                <button
                                  className="btn btn-sm btn-dark float-end fs-6 mt-5"
                                  onClick={updateRequisition}
                                  disabled={block}
                                >
                                  Update
                                </button>
                              )}
                            </PermissionAbility>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={`tab-pane fade ${
                    tab == "files" ? "active show" : ""
                  }`}
                  id="files"
                  role="tabpanel"
                >
                  <div className="card card-custom gutter-b">
                    <div className="card-body px-0">
                      <div className="card mb-5 mb-xl-8">
                        <div className="card-body py-3">
                          <form
                            id="attachment-form"
                            encType="multipart/form-data"
                          >
                            <NewDropzone onDrop={uploadFile} />
                          </form>
                          <div className="table-responsive">
                            <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
                              <thead>
                                <tr className="fw-bolder text-muted">
                                  <th className="min-w-50px">SL</th>
                                  <th className="min-w-120px">File Name</th>
                                  <th className="min-w-120px">Action</th>
                                </tr>
                              </thead>

                              <tbody>
                                {file?.data?.map((item, index) => (
                                  <tr key={index}>
                                    <td className="">{index + 1}</td>
                                    <td className=" fw-bolder mb-1 fs-6">
                                      <span>{item?.file_name}</span>
                                    </td>
                                    <td className=" fw-bolder mb-1 fs-6">
                                      <button
                                        className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                                        onClick={() => {
                                          setConfirmDelete(true);
                                          setuuid(item.uuid);
                                          setModelId(item.model_id);
                                        }}
                                      >
                                        <i className="fa fa-trash"></i>
                                      </button>
                                      <a
                                        className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                                        href={item?.original_url}
                                        target="_blank"
                                      >
                                        <i className="fa fa-download"></i>
                                      </a>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Activities logName="requisitions" modelId={id} tab={tab} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Confirmation
        open={confirmDelete}
        onConfirm={() => {
          setConfirmDelete(false);
          deleteItem();
        }}
        onCancel={() => setConfirmDelete(false)}
      />
    </>
  );
};

export default ShowRequisition;
