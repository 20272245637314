import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Moment from "react-moment";
import QuotationService from "../../../services/QuotationService";

const PrintQuotation = () => {
  let { id } = useParams();
  const [quotation, setQuotation] = useState(null);

  const getQuotation = async () => {
    let res = await QuotationService.getService(id);
    setQuotation(res);
  };
  useEffect(() => {
    if (id) getQuotation();
  }, [id]);

  const [total, setTotal] = useState(null);

  useEffect(() => {
    let inTotal = 0;
    if (quotation) {
      quotation?.service_items?.forEach((element) => {
        inTotal = inTotal + element?.amount;
      });
      setTotal(inTotal);
    }
  }, [quotation]);

  useEffect(() => {
    if (total != null) {
      let content = document.getElementById("content").innerHTML;
      document.body.innerHTML = content;
      setTimeout(() => {
        window.print();
      }, 500);
    }
  }, [total]);

  return (
    <div className="post" id="content">
      <div className="container-xxl">
        <div className="card">
          <div className="card-body py-10">
            <div className="mw-lg-950px mx-auto w-100">
              <div className="">
                <div className="d-flex justify-content-center">
                  <img
                    src="/assets/media/logos/gen-air.png"
                    height="80px"
                    width="180px"
                    alt="logo"
                  />
                </div>
                <div className="mt-5">
                  <h1 className="text-center">Quotation</h1>
                </div>
                <table width="100%">
                  <tr>
                    <td>
                      <h6>
                        <strong>Company Name: </strong>
                        <span className="text-muted">
                          {quotation?.company?.name}
                        </span>
                      </h6>
                      <h6>
                        <strong>Company Email: </strong>
                        <span className="text-muted">
                          {quotation?.company?.email}
                        </span>
                      </h6>
                    </td>
                    <td style={{ marginLeft: "120px" }} width="20%"></td>

                    <td style={{ marginLeft: "120px" }}>
                      <h6>
                        <strong>Date: </strong>
                        <span className="text-muted">
                          <Moment format="D MMMM YYYY">
                            {quotation?.created_at}
                          </Moment>
                        </span>
                      </h6>
                      <h6>
                        <strong>Phone: </strong>
                        <span className="text-muted">
                          {quotation?.company?.tel}
                        </span>
                      </h6>
                      <h6>
                        <strong>Issued By: </strong>
                        <span className="text-muted">
                          {quotation?.user?.name}
                        </span>
                      </h6>
                    </td>
                  </tr>
                </table>

                {/* Machine Problems */}
                <div className="d-flex justify-content-between flex-column flex-md-row">
                  <div className="flex-grow-1">
                    <h6>
                      Qoutation Type:{" "}
                      <span className="text-muted">
                        {quotation?.type?.replaceAll("_", " ")?.capitalize()}
                      </span>
                    </h6>
                  </div>
                </div>
                <div className="d-flex justify-content-between flex-column flex-md-row">
                  <div className="flex-grow-1">
                    <h6 className="" style={{ textDecoration: "underline" }}>
                      Machine Problem Details
                    </h6>
                    <p className="">{quotation?.machine_problems}</p>
                  </div>
                </div>
                {/* Solutions */}
                <div className="d-flex justify-content-between flex-column flex-md-row">
                  <div className="flex-grow-1">
                    <h6 className="" style={{ textDecoration: "underline" }}>
                      Solutions/Counter Measure
                    </h6>
                    <p className="">{quotation?.solutions}</p>
                  </div>
                </div>
                {/* Reason Of Trouble */}
                <div className="d-flex justify-content-between flex-column flex-md-row">
                  <div className="flex-grow-1">
                    <h6 className="" style={{ textDecoration: "underline" }}>
                      Reasons Of trouble
                    </h6>
                    <p className="">{quotation?.reason_of_trouble}</p>
                  </div>
                </div>

                <div className="d-flex justify-content-between flex-column flex-md-row">
                  <div className="flex-grow-1 pt-2">
                    <div className="table-responsive ">
                      <table className="table">
                        <thead className="m-20">
                          <tr className="fs-6 fw-bolder text-dark text-uppercase">
                            <th className="text-center">
                              <div
                                className="p-1"
                                style={{
                                  backgroundColor: "#FD7E14",
                                  color: "#fff",
                                }}
                              >
                                {" "}
                                SL.No{" "}
                              </div>
                            </th>
                            <th className="text-start w-50 ">
                              <div
                                className="p-1"
                                style={{
                                  backgroundColor: "#009EF7",
                                  color: "#fff",
                                }}
                              >
                                {" "}
                                Service Name{" "}
                              </div>
                            </th>
                            <th className="text-center">
                              <div
                                className="p-1"
                                style={{
                                  backgroundColor: "#FD7E14",
                                  color: "#fff",
                                }}
                              >
                                {" "}
                                Amount{" "}
                              </div>
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {quotation?.service_items?.map((item, idx) => {
                            return (
                              <tr
                                className="text-dark border-bottom border-1 border-dark"
                                key={idx}
                              >
                                <td className=" text-center">{idx + 1}</td>
                                <td className="text-start">
                                  {" "}
                                  <h6>{item?.service?.name}</h6>
                                </td>
                                <td className="text-end m-2">
                                  <div>{item?.service?.amount}</div>
                                </td>
                              </tr>
                            );
                          })}
                          {/* <tr className="text-dark border-bottom border-1 border-dark">
                            <td colSpan={2} className="text-end">
                              <h6>Total</h6>
                            </td>
                            <td className="text-end m-2">{total}</td>
                          </tr> */}
                        </tbody>
                      </table>
                      <div className="text-dark fw-bolder text-uppercase mt-1 text-end font-size fs-4">
                        Total Amount : {parseInt(quotation?.sub_total)}
                      </div>
                      <div className="d-flex justify-content-end mt-3">
                        <label className="form-label fs-6 text-uppercase text-dark d-flex align-items-center m-2">
                          vat ({quotation?.vat})% :
                        </label>
                        <div className="text-dark text-uppercase mt-1 text-end font-size fs-4">
                          {(quotation?.sub_total * quotation?.vat) / 100}
                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        <label className="form-label fs-6 text-uppercase text-dark d-flex align-items-center m-2">
                          discount ({quotation?.discount})% :
                        </label>
                        <div className="text-danger text-uppercase mt-1 text-end font-size fs-4">
                          {(quotation?.sub_total * quotation?.discount) / 100}
                        </div>
                      </div>
                      <div className="text-dark fw-bolder text-uppercase mt-2 text-end font-size fs-4">
                        grand Amount : {quotation?.grand_total}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="fixed-bottom mb-10 text-center border-top border-1 border-dark mt-3">
                  <div className="d-flex flex-row justify-content-evenly">
                    <div className="d-flex flex-row">
                      <div className="m-2 p-2 border border-1 rounded-circle border-dark">
                        <span>
                          <img
                            src="https://img.icons8.com/ios/344/city-buildings.png"
                            style={{ height: 35, weight: 35 }}
                          />
                        </span>
                      </div>
                      <div className="pt-2 text-start">
                        <h4>Dhaka Office</h4>
                        <span>H# 8/1, R#4, Metro Development</span>
                        <br />
                        <span>Housing Ltd. Mohammadpur, Dhaka</span>
                      </div>
                    </div>
                    <div className="d-flex flex-row">
                      <div className="m-2 p-2 border border-1 rounded-circle border-dark">
                        <span>
                          <img
                            src="https://img.icons8.com/ios/344/city-buildings.png"
                            style={{ height: 35, weight: 35 }}
                          />
                        </span>
                      </div>
                      <div className="pt-2 text-start">
                        <h4>Chittagong Office</h4>
                        <span>H# 67A, R# 28A, B# C</span>
                        <br />
                        <span>Nizam Road, Panchlaish, Chittagong</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrintQuotation;
