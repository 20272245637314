import Table from "components/utils/Table";
import PermissionAbility from "helpers/PermissionAbility";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import QuotationService from "services/QuotationService";
import QuotationFilter from "./QuotationFilter";
import Confirmation from "components/utils/Confirmation";
import Moment from "react-moment";

const Quotations = () => {
  const [filter, setFilter] = useState(false);
  const [loading, setLoading] = useState(true);
  const [quotations, setQuotations] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [quoId, setQuoId] = useState(null);

  const filterdata = (data) => {
    setFilter(false);
    getQuotations(data);
  };

  useEffect(() => {
    filterdata();
  }, []);

  const columns = [
    {
      name: "Id",
      selector: (row) => row?.pq_number,
      sortable: true,
      field: "id",
    },
    {
      name: "Company",
      selector: (row) => row?.company?.name,
      sortable: true,
      field: "company",
      format: (row) => (
        <div className="d-flex align-items-center">
          <div className="d-flex justify-content-start flex-column">
            <Link
              to={"/panel/companies/" + row?.company?.id}
              className="text-dark fw-bolder text-hover-primary"
            >
              {row?.company?.name}
            </Link>
          </div>
        </div>
      ),
    },
    {
      name: "Expected Delivery",
      selector: (row) => row?.expected_delivery,
      sortable: true,
      field: "expected_delivery",
      format: (row) => (
        <div className="mt-2">
          {row?.expected_delivery ? row?.expected_delivery : "--"}
        </div>
      ),
    },
    {
      name: "Priority",
      selector: (row) => row.priority,
      sortable: true,
      field: "role",
    },
    {
      name: "Status",
      selector: (row) => row?.status,
      sortable: true,
      field: "role",
      format: (row) => (
        <>
          {row?.status == "pending" && (
            <div className="mt-2 text-white bg-warning p-1 px-2 rounded">
              Pending
            </div>
          )}
          {row?.status == "approved" && (
            <div className="mt-2 text-white bg-success p-1 px-2 rounded">
              Approved
            </div>
          )}
          {row?.status == "rejected" && (
            <div className="mt-2 text-white bg-danger p-1 px-2 rounded">
              Rejected
            </div>
          )}
        </>
      ),
    },

    {
      name: "RQ Number",
      selector: (row) => row?.requisition,
      sortable: true,
      field: "role",
      format: (row) => (
        <div className="mt-2">
          {row?.requisition
            ? row?.requisition
            : "No Requisition yet"}
        </div>
      ),
    },

    {
      name: "Created At",
      selector: (row) => row?.created_at,
      sortable: true,
      field: "created_at",
      format: (row) => (
        <div className="mt-2">
          <Moment format="D MMMM YYYY">{row?.created_at}</Moment>
        </div>
      ),
    },

    {
      name: "Action",
      selector: (row) => row.status,
      format: (row) => (
        <span className="text-end">
          <PermissionAbility permission="foc_quotations_show">
            <Link
              to={"/panel/foc-quotation/" + row.id}
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
            >
              <i className="fa fa-eye"></i>
            </Link>
          </PermissionAbility>
          {!row?.quotation?.rq_number && (
            <PermissionAbility permission="foc_quotations_delete">
              <Link
                to="#"
                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                onClick={() => {
                  setQuoId(row.id);
                  setConfirmDelete(true);
                }}
              >
                <i className="fa fa-trash"></i>
              </Link>
            </PermissionAbility>
          )}
        </span>
      ),
    },
  ];

  const getQuotations = async (filters) => {
    let res = await QuotationService.getAllFoc(filters);
    setQuotations(res);
    setLoading(false);
  };

  const deleteQuotation = (quoId) => {
    QuotationService.remove(quoId);
    getQuotations();
    setLoading(false);
  };

  useEffect(() => {
    getQuotations();
  }, []);

  let navigate = useNavigate();

  const routeChange = () => {
    let path = `create`;
    navigate(path);
  };
  return (
    <>
      <div className="post d-flex flex-column-fluid">
        <div className="container-xxl">
          <Table
            name="Quotation"
            buttonName="Add FOC Quotation"
            buttonPermission="foc_quotations_create"
            onClickButton={routeChange}
            isLoading={loading}
            data={quotations}
            columns={columns}
            onFilter={filterdata}
          />
        </div>
      </div>
      {filter && (
        <QuotationFilter
          onClickOutside={() => {
            setFilter(!filter);
          }}
          enable={filter}
          onChange={(data) => {
            filterdata(data);
          }}
        />
      )}
      <Confirmation
        open={confirmDelete}
        onConfirm={() => {
          setConfirmDelete(false);
          deleteQuotation(quoId);
        }}
        onCancel={() => setConfirmDelete(false)}
      />
    </>
  );
};

export default Quotations;
