import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
const InvoicePartItems = ({ tab, active, type, partItems,sub_total,ait,vat,discount,grand_total }) => {
  const [data, setData] = useState(null);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (data) {
      if (data?.type == "claim_request") {
        setTotal(0);
      } else {
        let total = 0;
        data?.items.map((item) => {
          total =
            parseInt(total) +
            parseInt(item?.quantity) * parseInt(item?.unit_value);
        });
        setTotal(total);
      }
    }
  }, [data]);

  useEffect(() => {
    if (partItems?.length) {
      setData({
        items: partItems,
        ait: ait,
        vat: vat,
        discount: discount,
        type: type,
      });
    }
  }, [partItems]);
  return (
    <div className="d-flex flex-column gacompanyIdgap-lg-10">
      <div className="card card-custom gutter-b">
        <div className="card-header card-header-tabs-line">
          <div className="card-toolbar">
            <div className="card-title">
              <h3 className="card-label">Part Items</h3>
            </div>
          </div>
        </div>

        <div className="card-body px-0">
          <div className="card mb-2 mb-xl-8">
            <div className="card-body py-1">
              <div className="table-responsive">
                <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
                  <thead>
                    <tr className="fw-bolder text-muted">
                      <th className="min-w-50px">Part Name</th>
                      <th className="min-w-120px">Part Number</th>
                      <th className="min-w-120px">Quantity</th>
                      <th className="min-w-120px">Unit </th>
                      <th className="min-w-120px">Total </th>
                    </tr>
                  </thead>

                  <tbody>
                    {data?.items?.map((item, index) => (
                      <tr key={index}>
                        <td className="">
                          <Link
                            to={"/panel/parts/" + item?.part?.id}
                            className="text-dark fw-bolder text-hover-primary"
                          >
                            {item?.part?.part_name?.name}
                          </Link>
                        </td>
                        <td className=" fw-bolder mb-1 fs-6">
                          <span>{item?.part?.part_name?.part_number}</span>
                        </td>

                        <td>
                          <span>{item.quantity}</span>
                        </td>
                        <td className=" fw-bolder mb-1 fs-6">
                          <span>{item?.unit_value}</span>
                        </td>

                        <td className=" fw-bolder mb-1 fs-6">
                          <span>{item?.unit_value * item.quantity}TK</span>
                        </td>
                      </tr>
                    ))}
                    {data?.type != "claim_request" && (
                      <>
                        <tr>
                          <td colSpan={2}></td>
                          <td className="fw-bolder" colSpan={2}>
                            <span>Total</span>
                          </td>
                          <td className="fw-bolder">{total}TK</td>
                        </tr>
                        <tr>
                          <td colSpan={2}></td>
                          <td className="fw-bolder" colSpan={2}>
                            <span>AIT({data?.ait}%)</span>
                          </td>
                          <td className="fw-bolder">
                            {(total * data?.ait) / 100}TK
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2}></td>
                          <td className="fw-bolder" colSpan={2}>
                            <span>Vat({data?.vat}%)</span>
                          </td>
                          <td className="fw-bolder">
                            {(total * data?.vat) / 100}TK
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2}></td>
                          <td className="fw-bolder" colSpan={2}>
                            <span>Discount({data?.discount}%)</span>
                          </td>
                          <td className="fw-bolder text-danger">
                            {(total * data?.discount) / 100}TK
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2}></td>
                          <td className="fw-bolder" colSpan={2}>
                            <span>Grand-total</span>
                          </td>
                          <td className="fw-bolder">
                            {grand_total}
                            TK
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoicePartItems;
