import PermissionAbility from "helpers/PermissionAbility";
import { PrivateRoute } from "helpers/PrivateRoute";
import Login from "pages/auth/Login";
import Dashboard from "pages/dashboard/Dashboard";
import Designations from "pages/designations/Index";
import ShowDesignation from "pages/designations/Show";
import Employee from "pages/employee/Index";
import ShowEmployee from "pages/employee/Show";
import Forbidden from "pages/Forbidden";
import AppLayout from "pages/layouts/AppLayout";
import NotFound from "pages/NotFound";
import Roles from "pages/roles/Index";
import ShowPermission from "pages/roles/Show";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

//////////////////////////////////old import files start ///////////////////////////////////////////////
//client start
//////////////////////////////////old import files end ///////////////////////////////////////////////

////////////////////////////////////////////////////new import files/////////////////////////////////////////////////////////////
import AccountSettings from "pages/auth/AccountSettings";
import Profile from "pages/auth/Profile";
import ShowUser from "pages/companies/users/Show";
//company
import Companies from "pages/companies/Index";
import ShowCompany from "pages/companies/Show";
//lc
import LC from "pages/lc/Index";
import LCShow from "pages/lc/Show";
//bin-location
import BinLocation from "pages/bin-location/Index";
import BinLocationShow from "pages/bin-location/Show";
//machine
import Machines from "pages/machines/Index";
import ShowMachineModel from "pages/machines/models/Show";
import ShowMachine from "pages/machines/Show";
//part
import PartName from "pages/part-name/Index";
import PartNameShow from "pages/part-name/Show";
import Parts from "pages/parts/Index";
import ShowPart from "pages/parts/Show";
//stock part
import StockParts from "pages/parts/stock-part/Index";
//purchase req

import QuotationCreate from "pages/quotation/Create";
import Quotation from "pages/quotation/Index";
import PrintQuotation from "pages/quotation/Print";
import ShowQuotation from "pages/quotation/Show";
//foc
import FocQuotationCreate from "pages/foc-quotation/Create";
import FocQuotation from "pages/foc-quotation/Index";
import ShowFOCQuotation from "pages/foc-quotation/Show";
//requisition
import PrintFOCQuotation from "pages/foc-quotation/Print";
import CreateRequisition from "pages/requisition/Create";
import Requisition from "pages/requisition/Index";
import PrintRequisition from "pages/requisition/Print";
import ShowRequisition from "pages/requisition/Show";
//invoices
import Invoices from "pages/invoices/Index";
import ShowPaymentHistories from "pages/invoices/paymentHistories/Show";
import PrintInvoice from "pages/invoices/Print";
import ShowInvoice from "pages/invoices/Show";
//delivery-notes
import CreateDelivery from "pages/delivery-notes/CreateDelivery";
import DeliveryNotes from "pages/delivery-notes/Index";
import PrintDeliveryNotes from "pages/delivery-notes/Print";
import ShowDeliveryNotes from "pages/delivery-notes/Show";
//service
import ServiceInvoice from "pages/invoices/service/Index";
import PrintServiceInvoice from "pages/invoices/service/Print";
import ShowServiceInvoice from "pages/invoices/service/Show";
import CreateServiceQuotation from "pages/quotation/Service/Create";
import ServiceQuotation from "pages/quotation/Service/Index";
import PrintServiceQuotation from "pages/quotation/Service/Print";
import ShowServiceQuotation from "pages/quotation/Service/Show";
import Services from "pages/services/Index";
import ServicesShow from "pages/services/Show";
//part summery
import LcPartSummery from "pages/parts/lc-part-summery/Index";
//trasaction summery
import TransactionSummary from "pages/transaction-summary/Index";
import ShowTransactionSummary from "pages/transaction-summary/Show";
//warehouse
import WareHouse from "pages/warehouses/Index";
import WareHouseShow from "pages/warehouses/Show";
//setting
import Settings from "pages/Settings/Index";
//report

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* Dashboard Start */}
          <Route path="/panel/*" element={<AppLayout />}>
            <Route
              path="dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            {/* Dashboard End */}
            {/* Employee Start */}
            <Route
              path="employees"
              element={
                <PrivateRoute>
                  <Employee />
                </PrivateRoute>
              }
            />
            <Route
              path="employees/:id"
              element={
                <PrivateRoute>
                  <ShowEmployee />
                </PrivateRoute>
              }
            />
            {/* Employee End */}
            {/* Designation Start */}
            <Route
              path="designations"
              element={
                <PrivateRoute>
                  <PermissionAbility permission="designations_access">
                    <Designations />
                  </PermissionAbility>
                </PrivateRoute>
              }
            />
            <Route
              path="designations/:id"
              element={
                <PrivateRoute>
                  <ShowDesignation />
                </PrivateRoute>
              }
            />
            {/* Designation End */}
            {/* Role Start */}
            <Route
              path="roles"
              element={
                <PrivateRoute>
                  <Roles />
                </PrivateRoute>
              }
            />
            <Route
              path="roles/:id"
              element={
                <PrivateRoute>
                  <ShowPermission />
                </PrivateRoute>
              }
            />
            {/* Role End */}
            {/* Company Start */}
            <Route
              path="companies"
              element={
                <PrivateRoute>
                  <Companies />
                </PrivateRoute>
              }
            />
            <Route
              path="companies/:id"
              element={
                <PrivateRoute>
                  <ShowCompany />
                </PrivateRoute>
              }
            />
            <Route
              path="companies/:companyId/users/:id"
              element={
                <PrivateRoute>
                  <ShowUser />
                </PrivateRoute>
              }
            />
            {/* Company End */}
            {/* Service Start */}
            <Route
              path="services"
              element={
                <PrivateRoute>
                  <Services />
                </PrivateRoute>
              }
            />
            <Route
              path="services/:id"
              element={
                <PrivateRoute>
                  <ServicesShow />
                </PrivateRoute>
              }
            />
            {/* Service End */}
            {/* LC Start */}
            <Route
              path="lc"
              element={
                <PrivateRoute>
                  <LC />
                </PrivateRoute>
              }
            />
            <Route
              path="lc/:id"
              element={
                <PrivateRoute>
                  <LCShow />
                </PrivateRoute>
              }
            />
            {/* LC End */}
            {/* WareHouse Start */}
            <Route
              path="warehouses"
              element={
                <PrivateRoute>
                  <WareHouse />
                </PrivateRoute>
              }
            />
            <Route
              path="warehouses/:id"
              element={
                <PrivateRoute>
                  <WareHouseShow />
                </PrivateRoute>
              }
            />
            {/* WareHouse End */}
            {/* Bin/location Start */}
            <Route
              path="bin-location"
              element={
                <PrivateRoute>
                  <BinLocation />
                </PrivateRoute>
              }
            />
            <Route
              path="bin-location/:id"
              element={
                <PrivateRoute>
                  <BinLocationShow />
                </PrivateRoute>
              }
            />
            {/* Bin/location End */}
            {/* Machine Start */}
            <Route
              path="machines"
              element={
                <PrivateRoute>
                  <Machines />
                </PrivateRoute>
              }
            />
            <Route
              path="machines/:id"
              element={
                <PrivateRoute>
                  <ShowMachine />
                </PrivateRoute>
              }
            />
            <Route
              path="machines/:machineId/models/:modelId"
              element={
                <PrivateRoute>
                  <ShowMachineModel />
                </PrivateRoute>
              }
            />
            {/* Machine End */}
            {/* PartName Start */}
            <Route
              path="part-name"
              element={
                <PrivateRoute>
                  <PartName />
                </PrivateRoute>
              }
            />
            <Route
              path="part-name/:id"
              element={
                <PrivateRoute>
                  <PartNameShow />
                </PrivateRoute>
              }
            />
            {/* PartName End */}
            {/* Parts start */}
            <Route
              path="parts"
              element={
                <PrivateRoute>
                  <Parts />
                </PrivateRoute>
              }
            />
            <Route
              path="parts/:id"
              element={
                <PrivateRoute>
                  <ShowPart />
                </PrivateRoute>
              }
            />
            {/* Parts End */}

            {/* stock part */}
            <Route
              path="stock-parts"
              element={
                <PrivateRoute>
                  <StockParts />
                </PrivateRoute>
              }
            />
            {/* lc part summery */}
            <Route
              path="lc-part-summery"
              element={
                <PrivateRoute>
                  <LcPartSummery />
                </PrivateRoute>
              }
            />
            {/* lc part summmery end */}
            {/* Purchase-Quotation Start */}
            <Route
              path="quotation/create"
              element={
                <PrivateRoute>
                  <QuotationCreate />
                </PrivateRoute>
              }
            />
            <Route
              path="quotation"
              element={
                <PrivateRoute>
                  <Quotation />
                </PrivateRoute>
              }
            />
            <Route
              path="quotation/:id"
              element={
                <PrivateRoute>
                  <ShowQuotation />
                </PrivateRoute>
              }
            />
            <Route
              path="quotation/:id/print"
              element={
                <PrivateRoute>
                  <PrintQuotation />
                </PrivateRoute>
              }
            />
            {/* Purchase-Quotation Start */}
            {/* FOC-Quotation Start  */}
            <Route
              path="foc-quotation"
              element={
                <PrivateRoute>
                  <FocQuotation />
                </PrivateRoute>
              }
            />
            <Route
              path="foc-quotation/:id"
              element={
                <PrivateRoute>
                  <ShowFOCQuotation />
                </PrivateRoute>
              }
            />
            <Route
              path="foc-quotation/:id/print"
              element={
                <PrivateRoute>
                  <PrintFOCQuotation />
                </PrivateRoute>
              }
            />
            <Route
              path="foc-quotation/create"
              element={
                <PrivateRoute>
                  <FocQuotationCreate />
                </PrivateRoute>
              }
            />
            {/* FOC-Quotation End */}
            {/* service requisition */}
            <Route
              path="services-quotation"
              element={
                <PrivateRoute>
                  <ServiceQuotation />
                </PrivateRoute>
              }
            />

            <Route
              path="services-quotation/create"
              element={
                <PrivateRoute>
                  <CreateServiceQuotation />
                </PrivateRoute>
              }
            />

            <Route
              path="services-quotation/:id"
              element={
                <PrivateRoute>
                  <ShowServiceQuotation />
                </PrivateRoute>
              }
            />

            <Route
              path="service-quotation/:id/print"
              element={
                <PrivateRoute>
                  <PrintServiceQuotation />
                </PrivateRoute>
              }
            />
            {/* service requisition end */}
            {/* Requisition Start */}
            <Route
              path="requisition/:id/create"
              element={
                <PrivateRoute>
                  <CreateRequisition />
                </PrivateRoute>
              }
            />
            <Route
              path="requisitions"
              element={
                <PrivateRoute>
                  <Requisition />
                </PrivateRoute>
              }
            />
            <Route
              path="requisitions/:id"
              element={
                <PrivateRoute>
                  <ShowRequisition />
                </PrivateRoute>
              }
            />
            <Route
              path="requisitions/:id/print"
              element={
                <PrivateRoute>
                  <PrintRequisition />
                </PrivateRoute>
              }
            />

            {/* Requisition End */}
            {/* Invoice Start */}
            <Route
              path="invoices"
              element={
                <PrivateRoute>
                  <Invoices />
                </PrivateRoute>
              }
            />
            <Route
              path="invoices/:id"
              element={
                <PrivateRoute>
                  <ShowInvoice />
                </PrivateRoute>
              }
            />
            <Route
              path="invoices/:id/print"
              element={
                <PrivateRoute>
                  <PrintInvoice />
                </PrivateRoute>
              }
            />
            <Route
              path="invoices/:id/payment-histories/:paymentId"
              element={
                <PrivateRoute>
                  <ShowPaymentHistories />
                </PrivateRoute>
              }
            />
            {/* Invoice End */}
            {/* service invoice */}
            <Route
              path="services-invoice"
              element={
                <PrivateRoute>
                  <ServiceInvoice />
                </PrivateRoute>
              }
            />

            <Route
              path="services-invoice/:id"
              element={
                <PrivateRoute>
                  <ShowServiceInvoice />
                </PrivateRoute>
              }
            />

            <Route
              path="service-invoices/:id/print"
              element={
                <PrivateRoute>
                  <PrintServiceInvoice />
                </PrivateRoute>
              }
            />
            {/* service invoice end */}
            {/* Delivery Notes Start */}
            <Route
              path="delivery-notes"
              element={
                <PrivateRoute>
                  <DeliveryNotes />
                </PrivateRoute>
              }
            />
            <Route
              path="delivery-notes/:id/print"
              element={
                <PrivateRoute>
                  <PrintDeliveryNotes />
                </PrivateRoute>
              }
            />
            <Route
              path="delivery-notes/:id"
              element={
                <PrivateRoute>
                  <ShowDeliveryNotes />
                </PrivateRoute>
              }
            />
            <Route
              path="delivery-notes/:invoiceId/create"
              element={
                <PrivateRoute>
                  <CreateDelivery />
                </PrivateRoute>
              }
            />
            {/* Sales management end */}
            {/* Accounts Start */}
            <Route
              path="transaction-summary"
              element={
                <PrivateRoute>
                  <TransactionSummary />
                </PrivateRoute>
              }
            />
            <Route
              path="transaction-summary/:id"
              element={
                <PrivateRoute>
                  <ShowTransactionSummary />
                </PrivateRoute>
              }
            />
            {/* Accounts End */}
            {/* new files end */}
            {/* Role End */}
            {/* Parts end */}
            <Route
              path="profile"
              element={
                <PrivateRoute>
                  <Profile />
                </PrivateRoute>
              }
            />
            <Route
              path="profile/settings"
              element={
                <PrivateRoute>
                  <AccountSettings />
                </PrivateRoute>
              }
            />
            {/* Settings Start */}
            <Route
              path="settings"
              element={
                <PrivateRoute>
                  <Settings />
                </PrivateRoute>
              }
            />
            {/* Settings End */}
            {/*///////////////////////////////////////////////////////////////////// comment start /////////////////////////////////////////////////*/}
            {/* Report Start */}
            {/* <Route
              path="reports"
              element={
                <PrivateRoute>
                  <Reports />
                </PrivateRoute>
              }
            />
            <Route
              path="part-stock-report"
              element={
                <PrivateRoute>
                  <PartStockReport />
                </PrivateRoute>
              }
            />
            <Route
              path="all-notification"
              element={
                <PrivateRoute>
                  <AllNotification />
                </PrivateRoute>
              }
            /> */}
            {/* Report End */}
            {/* Gate pass Start */}
            {/* <Route
              path="gate-passes"
              element={
                <PrivateRoute>
                  <GatePass />
                </PrivateRoute>
              }
            />
            <Route
              path="client/all-notification"
              element={
                <PrivateRoute>
                  <ClientAllNotification />
                </PrivateRoute>
              }
            />
            <Route
              path="client/dashboard"
              element={
                <PrivateRoute>
                  <ClientDashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="companies/user"
              element={
                <PrivateRoute>
                  <CompanyUser />
                </PrivateRoute>
              }
            />
            <Route
              path="companies/user/:id"
              element={
                <PrivateRoute>
                  <CompanyUserShow />
                </PrivateRoute>
              }
            />
            <Route
              path="company/user/machines"
              element={
                <PrivateRoute>
                  <ClientCompanyMachines />
                </PrivateRoute>
              }
            />
            <Route
              path="companies/user/contracts"
              element={
                <PrivateRoute>
                  <ClientContract />
                </PrivateRoute>
              }
            />
            <Route
              path="client/contracts/:id"
              element={
                <PrivateRoute>
                  <ClientContractShow />
                </PrivateRoute>
              }
            /> */}
            {/* client contract end */}
            {/* company user sales start */}
            {/* client requisition start */}
            {/* <Route
              path="client-requisitions"
              element={
                <PrivateRoute>
                  <ClientRequisitions />
                </PrivateRoute>
              }
            />
            <Route
              path="client-requisitions/create"
              element={
                <PrivateRoute>
                  <ClientRequisitionCreate />
                </PrivateRoute>
              }
            />
            <Route
              path="client/requisitions/:id"
              element={
                <PrivateRoute>
                  <ShowClientRequisition />
                </PrivateRoute>
              }
            />
            <Route
              path="client/requisitions/:id/print"
              element={
                <PrivateRoute>
                  <PrintClientRequisition />
                </PrivateRoute>
              }
            /> */}
            {/* client requisition end */}
            {/* client required requisition start */}
            {/* <Route
              path="client-required-requisitions"
              element={
                <PrivateRoute>
                  <ClientRequiredRequisitions />
                </PrivateRoute>
              }
            /> */}
            {/* <Routereports
                  <ClientRequisitionCreate />
                </PrivateRoute>
              }
            /> */}
            {/* <Route
              path="client_require_req/:id"
              element={
                <PrivateRoute>
                  <ClientShowRequiredRequisition />
                </PrivateRoute>
              }
            /> */}
            {/* <Route
              path="client/requisitions/:id/print"
              element={
                <PrivateRoute>
                  <PrintClientRequisition />
                </PrivateRoute>
              }
            /> */}
            {/* client required requisition end */}
            {/* client quotation start */}
            {/* <Route
              path="client-quotations"
              element={
                <PrivateRoute>
                  <ClientQuotation />
                </PrivateRoute>
              }
            />
            <Route
              path="client/quotations/:requisitionId/create"
              element={
                <PrivateRoute>
                  <CreateClientQuotation />
                </PrivateRoute>
              }
            />
            <Route
              path="client/quotations/:id"
              element={
                <PrivateRoute>
                  <ShowClientQuotation />
                </PrivateRoute>
              }
            />
            <Route
              path="client/quotations/:id/print"
              element={
                <PrivateRoute>
                  <PrintClientQuotation />
                </PrivateRoute>
              }
            /> */}
            {/* client quotation end */}
            {/* <Route
              path="client-invoice"
              element={
                <PrivateRoute>
                  <ClientInvoices />
                </PrivateRoute>
              }
            />
            <Route
              path="client/invoices/:id"
              element={
                <PrivateRoute>
                  <ShowClientInvoice />
                </PrivateRoute>
              }
            />
            <Route
              path="client/invoices/:id/print"
              element={
                <PrivateRoute>
                  <PrintClientInvoice />
                </PrivateRoute>
              }
            /> */}
            {/* delivery note */}
            {/* <Route
              path="client-delivery-notes"
              element={
                <PrivateRoute>
                  <ClientDeliveryNotes />
                </PrivateRoute>
              }
            />
            <Route
              path="client/delivery-notes/:invoiceId/create"
              element={
                <PrivateRoute>
                  <CreateClientDelivery />
                </PrivateRoute>
              }
            />
            <Route
              path="client/delivery-notes/:id"
              element={
                <PrivateRoute>
                  <ShowClientDeliveryNotes />
                </PrivateRoute>
              }
            />
            <Route
              path="client/delivery-notes/:id/print"
              element={
                <PrivateRoute>
                  <PrintClientDeliveryNotes />
                </PrivateRoute>
              }
            /> */}
            {/* company user sales end */}
            {/* client foc start */}
            {/* <Route
              path="client-claim-requisitions"
              element={
                <PrivateRoute>
                  <ClientClaimRequisition />
                </PrivateRoute>
              }
            />
            <Route
              path="client-claim-requests"
              element={
                <PrivateRoute>
                  <ClientClaimRequest />
                </PrivateRoute>
              }
            />
            <Route
              path="client-claim-requests/:id"
              element={
                <PrivateRoute>
                  <ShowClientClaimRequest />
                </PrivateRoute>
              }
            />
            <Route
              path="client-claim-requests/create"
              element={
                <PrivateRoute>
                  <CreateClientClaimRequisition />
                </PrivateRoute>
              }
            />
            <Route
              path="client-claim-requisitions/:id"
              element={
                <PrivateRoute>
                  <ShowClientClaimRequisition />
                </PrivateRoute>
              }
            /> */}
            {/*////////////////////////////////////////////////////////////////// client foc end ///////////////////////////////////////////////// */}
            <Route path="403" element={<Forbidden />} />
            <Route path="*" element={<NotFound />} />
          </Route>

          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<Login />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
