import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import DatePicker from "react-datepicker";
import { DebounceInput } from "react-debounce-input";
import Select from "react-select";
import CompanyService from "services/CompanyService";
import QuotationService from "services/QuotationService";
import { toast } from "react-toastify";
import ServicesService from "services/ServicesService";

const QuotationServiceCreate = () => {
  const navigate = useNavigate();
  const [block, setBlock] = useState(false);

  const [machineId, setMachineId] = useState([]);
  const [filter, setFilter] = useState({});
  const [list, setList] = useState([]);
  const [selectedPart, setSelectedPart] = useState(false);
  const [totalAmount, setTotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);

  const [companies, setCompanies] = useState([]);
  const getCompanies = async () => {
    let dt = await CompanyService.getAll({
      rows: "all",
    });
    dt = dt.map((itm) => ({ label: itm.name, value: itm.id }));
    setCompanies(dt);
  };

  const [engineers, setEngineers] = useState([]);
  const getEngineers = async () => {
    let dt = await QuotationService.engineers();
    dt = dt.map((itm) => ({ label: itm?.name, value: itm?.id }));
    setEngineers(dt);
  };

  const [data, setData] = useState({
    company_id: "",
    engineer_id: "",
    machine_id: "",
    priority: "",
    type: "service_request",
    payment_mode: "",
    account_details: "",
    expected_delivery: "",
    payment_term: "",
    payment_partial_mode: "",
    partial_time: "",
    next_payment: "",
    ref_number: "",
    machine_problems: "",
    solutions: "",
    reason_of_trouble: "",
    remarks: "",
    vat: 0,
    discount: 0,
    service_items: list,
    files: [],
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const testData = queryParams.get("data");
    setData(JSON.parse(testData));
  }, []);

  const priorities = [
    { value: "low", label: "Low" },
    { value: "medium", label: "Medium" },
    { value: "high", label: "High" },
  ];

  const payments = [
    { value: "cash", label: "Cash" },
    { value: "bank", label: "Bank" },
    { value: "check", label: "Check" },
    { value: "card", label: "Card" },
  ];

  const addPart = (item) => {
    item["quantity"] = 0;
    let hasItem = list.find((itm) => itm.id == item.id);
    if (hasItem) return false;

    const newList = list.concat(item);
    setList(Array.from(new Set(newList)));
    setSelectedPart(true);
    setFilter({ ...filter, q: "" });
    setSearchData("");
  };

  const removeItem = (id) => {
    const newList = list.filter((item) => item.id !== id);
    setList(newList);
  };

  const handleSelect = (option, conf) => {
    if (conf.name == "machine_id") {
      const res = option?.map((itm) => itm?.machineId);
      setMachineId(res);
    }

    let value = option.value;
    if (Array.isArray(option))
      value = option.map((dt) => {
        return dt.value;
      });

    const name = conf.name;
    setBlock(false);

    setData({
      ...data,
      [name]: value,
    });
  };

  const handleChange = (e) => {
    const { name } = e.target;
    setData({ ...data, [name]: e.target.value });
    setBlock(false);
  };

  const handleDataChange = (e) => {
    let value;
    if (e.target.name == "vat" || e.target.name == "discount") {
      value = Math.min(100, Number(e.target.value));
    } else {
      value = e.target.value;
    }
    setData({ ...data, [e.target.name]: value });
  };

  const handleDateSelect = (value, name) => {
    setData({
      ...data,
      ...{
        [name]: new Date(value),
        [name + "_format"]: moment(value).format("YYYY/MM/DD"),
      },
    });
    setBlock(false);
  };

  
  const storeRequisition = async () => {
    setBlock(true);
    if (data?.service_items?.length < 0) {
      toast.warning("Part Quantity can't be 0!");
    } else {
      await QuotationService.createService(data);
      navigate("/panel/services-quotation");
    }
    setBlock(false);
  };
  
  const [searchData, setSearchData] = useState([]);
  const getServices = async () => {
    let res = await ServicesService.getAll({
      ...filter,
    });
    setSearchData(res.data);
    let items = res.data?.map((dt) => {
      return { label: dt.name, value: dt.id };
    });
  };
  
  const filterData = (e) => {
    let query = e.target.value;
    setFilter({
      ...filter,
      q: query,
    });
  };
  
  const search = async (e) => {
    await getServices();
    if (filter?.q === "") setSearchData([]);
  };
  useEffect(() => {
    if (filter?.q) {
      search();
    }
  }, [filter, machineId]);
  
  useEffect(() => {
    setData({ ...data, service_items: list, sub_total: totalAmount,grand_total: grandTotal }); //add part_items and total amount in data
  }, [list, totalAmount,grandTotal]);

  useEffect(() => {
    setGrandTotal(
      Math.ceil(totalAmount * (1 + (data?.vat - data?.discount) / 100))
    );
  }, [data?.vat, data?.discount, data?.service_items]);
  
  useEffect(() => {
    getCompanies();
    getEngineers();
    // getMachineModels();
  }, []);

  useEffect(() => {
    const sum = list.reduce(
      (partialSum, a) => parseInt(partialSum) + parseInt(a.amount),
      0
    );
    setTotal(sum);
  }, [list]);

  return (
    <>
      <div className="post d-flex flex-column-fluid">
        <div className="container-xxl">
          <div className="d-flex flex-column flex-lg-row">
            <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">
              <div className="card mb-5">
                <div className="card-body p-12">
                  <form action="" id="kt_invoice_form">
                    <div className="d-flex flex-column align-items-start flex-xxl-row">
                      <div className="d-flex align-items-center flex-equal fw-row me-4 order-2">
                        {/* <input
                          type="text"
                          className="form-control w-50"
                          name="ref_number"
                          placeholder="Ref Number"
                          onChange={handleChange}
                        />
                        <div
                          className="fv-plugins-message-container invalid-feedback"
                          htmlFor="ref_number"
                        ></div> */}
                      </div>

                      <div className="d-flex flex-center flex-equal fw-row text-nowrap order-1 order-xxl-2 me-4">
                        <span className="fs-2x fw-bolder text-gray-800">
                          Service Quotation
                        </span>
                      </div>

                      <div className="d-flex align-items-center justify-content-end flex-equal order-3 fw-row">
                        <span className="fs-5">
                          Date: {moment().format("DD-MM-YYYY")}
                        </span>
                      </div>
                    </div>

                    <div className="separator separator-dashed my-10"></div>

                    <div className="row gx-10 mb-5">
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label className="required form-label">Company</label>
                          <Select
                            options={companies}
                            onChange={handleSelect}
                            name="company_id"
                          />

                          <div
                            className="fv-plugins-message-container invalid-feedback"
                            htmlFor="company_id"
                          ></div>
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <label className="required form-label">Engineer</label>

                        <div className="mb-5">
                          <Select
                            options={engineers}
                            onChange={handleSelect}
                            name="engineer_id"
                          />
                          <div
                            className="fv-plugins-message-container invalid-feedback"
                            htmlFor="engineer_id"
                          ></div>
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <label className="required form-label">Priority</label>
                        <div className="mb-5">
                          <Select
                            options={priorities}
                            name="priority"
                            onChange={handleSelect}
                          />
                          <div
                            className="fv-plugins-message-container invalid-feedback"
                            htmlFor="priority"
                          ></div>
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <label className="form-label">Expected Delivery</label>
                        <div className="mb-5">
                          <div className="form-group ">
                            <DatePicker
                              className="form-control"
                              name="expected_delivery"
                              selected={data.expected_delivery}
                              onChange={(date) =>
                                handleDateSelect(date, "expected_delivery")
                              }
                            />
                            <input
                              type="hidden"
                              name="expected_delivery"
                              id="expected_delivery"
                              value={data.expected_delivery}
                            />
                            <div
                              className="fv-plugins-message-container invalid-feedback"
                              htmlFor="expected_delivery"
                            ></div>
                          </div>
                        </div>
                      </div>

                      <>
                        <div className="col-lg-4">
                          <div className="mb-5">
                            <label className="required form-label">
                              Payment Mode
                            </label>
                            <Select
                              options={payments}
                              name="payment_mode"
                              onChange={handleSelect}
                            />
                            <div
                              className="fv-plugins-message-container invalid-feedback"
                              htmlFor="payment_mode"
                            ></div>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <label
                            className="required form-label fs-6 fw-bolder text-gray-700"
                            htmlFor="types"
                          >
                            Account Details
                          </label>
                          <textarea
                            className="form-control form-control-solid mb-3"
                            rows="2"
                            name="account_details"
                            data-kt-element="input"
                            placeholder="Account details"
                            onChange={handleChange}
                          ></textarea>
                          <div
                            className="fv-plugins-message-container invalid-feedback"
                            htmlFor="account_details"
                          ></div>
                        </div>
                      </>

                      <div className="col-lg-6">
                        <label
                          className="form-label fs-6 fw-bolder text-gray-700"
                          htmlFor="types"
                        >
                          Machine Problems
                        </label>
                        <textarea
                          className="form-control form-control-solid mb-3"
                          rows="5"
                          name="machine_problems"
                          data-kt-element="input"
                          placeholder="Type a message"
                          onChange={handleChange}
                        ></textarea>
                      </div>

                      <div className="col-lg-6">
                        <label
                          className="form-label fs-6 fw-bolder text-gray-700"
                          htmlFor="types"
                        >
                          Solutions
                        </label>
                        <textarea
                          className="form-control form-control-solid mb-3"
                          rows="5"
                          name="solutions"
                          data-kt-element="input"
                          placeholder="Type a message"
                          onChange={handleChange}
                        ></textarea>
                      </div>

                      <div className="col-lg-6">
                        <label
                          className="form-label fs-6 fw-bolder text-gray-700"
                          htmlFor="types"
                        >
                          Reason of Trouble
                        </label>
                        <textarea
                          className="form-control form-control-solid mb-3"
                          rows="5"
                          name="reason_of_trouble"
                          data-kt-element="input"
                          placeholder="Type a message"
                          onChange={handleChange}
                        ></textarea>
                      </div>

                      <div className="col-lg-6">
                        <label className="form-label fs-6 fw-bolder text-gray-700">
                          Remarks
                        </label>
                        <textarea
                          name="remarks"
                          className="form-control form-control-solid"
                          rows="5"
                          placeholder="Thanks for your business"
                          onChange={handleChange}
                        ></textarea>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <span>
            <div className="d-flex flex-column flex-lg-row">
              <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">
                <div className="card mb-5">
                  <div className="card-body p-12">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="form-group">
                          <div
                            className="fv-plugins-message-container invalid-feedback"
                            htmlFor="part_heading_id"
                          ></div>
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="form-group mt-2">
                          <label htmlFor=""></label>
                          <DebounceInput
                            className="form-control"
                            placeholder="search"
                            debounceTimeout={300}
                            onChange={filterData}
                          />

                          <div>
                            {searchData.length > 0 ? (
                              <div className="card border border-secondary ">
                                <div className="card-body ">
                                  {searchData?.map((item, index) => (
                                    <div key={index}>
                                      <Link
                                        to={item?.id}
                                        style={{ color: "black" }}
                                        onClick={() => addPart(item)}
                                      >
                                        <p>
                                          {item?.name}
                                          <span>({item.amount})</span>
                                        </p>
                                      </Link>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!selectedPart && (
              <div className="d-flex flex-column flex-lg-row">
                <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">
                  <div className="p-20 bg-white mb-2 text-center">
                    No items selected
                  </div>
                </div>
              </div>
            )}
            {selectedPart && list.length > 0 ? (
              <div className="d-flex flex-column flex-lg-row">
                <div className="flex-lg-row-fluid mb-lg-0 me-lg-7 me-xl-10">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="table-responsive">
                            <table
                              className="table g-5 gs-0 mb-0 fw-bolder text-gray-700"
                              data-kt-element="items"
                            >
                              <thead>
                                <tr className="border-bottom fs-7 fw-bolder text-gray-700 text-uppercase">
                                  <th className="min-w-200px w-375px">
                                    Service
                                  </th>
                                  <th className="min-w-200px w-375px">
                                    Amount
                                  </th>
                                  <th className="min-w-75px w-75px text-end">
                                    Action
                                  </th>
                                </tr>
                              </thead>

                              <tbody>
                                {list?.map((item, index) => (
                                  <tr key={index}>
                                    <td className="pe-7" name="name">
                                      {item?.name}
                                    </td>
                                    <td name="amount">{item?.amount}</td>
                                    <td className="text-end">
                                      <button
                                        type="button"
                                        className="btn btn-sm btn-icon btn-danger"
                                        data-kt-element="remove-item"
                                        onClick={() => removeItem(item?.id)}
                                      >
                                        <i className="fa fa-trash"></i>
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                      <div className="separator separator-dashed"></div>

                      <div className="text-gray-700 text-uppercase mt-5 text-end font-size h2">
                        Sub Total : {parseInt(totalAmount)}
                      </div>
                      <div className="d-flex justify-content-end mt-5">
                        <label className="form-label fs-3 fw-bolder text-gray-700 d-flex align-items-center m-2">
                          vat
                        </label>
                        <input
                          type="number"
                          pattern="[0-99]*"
                          className="form-control form-control-solid w-150px mx-5"
                          onChange={handleDataChange}
                          name="vat"
                          value={data?.vat}
                        />
                        <div className="text-gray-700 text-uppercase mt-5 text-end font-size h2">
                          {(totalAmount * data?.vat) / 100}
                        </div>
                      </div>
                      <div className="d-flex justify-content-end mt-5">
                        <label className="form-label fs-3 fw-bolder text-gray-700 d-flex align-items-center m-2">
                          Discount
                        </label>
                        <input
                          type="number"
                          className="form-control form-control-solid w-150px mx-5"
                          onChange={handleDataChange}
                          name="discount"
                          value={data?.discount}
                        />
                        <div className="text-gray-700 text-uppercase mt-5 text-end font-size h2">
                          {(totalAmount * data?.discount) / 100}
                        </div>
                      </div>
                      <div className="text-gray-700 text-uppercase mt-5 text-end font-size h2">
                        Grand Total : {parseInt(grandTotal)}
                      </div>
                      <div className="mt-5">
                        <button
                          onClick={() => {
                            storeRequisition();
                          }}
                          className="btn btn-primary"
                          disabled={block}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </span>
        </div>
      </div>
    </>
  );
};

export default QuotationServiceCreate;
