import Confirmation from "components/utils/Confirmation";
import Table from "components/utils/Table";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PartService from "services/PartService";
import CreateContract from "./Create";
import CreatePartStock from "./CreatePartStock";
import EditPart from "./Edit";
import ImportFile from "./ImportFile";
import PartFilter from "./PartFilter";

const Parts = () => {
  const [loading, setLoading] = useState(true);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [parts, setParts] = useState([]);
  const [partId, setPartId] = useState(null);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openAddStockModal, setOpenAddStockModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [part, setPart] = useState(null);
  const [enableFilter, setEnableFilter] = useState(false);
  const [filter, setFilter] = useState({});

  const columns = [
    {
      name: "Name",
      selector: (row) => row.part_name_id ?? "--",
      sortable: true,
      width: "25%",
      wrap: true,
      field: "part_name_id",
      format: (row) => (
        <div className="d-flex align-items-center">
          <div className="symbol symbol-50px me-5">
            <span className="symbol-label bg-light">
              <img
                src={row?.image_url}
                className="h-75 overflow-hidden"
                alt={row?.part_name?.name}
              />
            </span>
          </div>
          <div className="d-flex justify-content-start flex-column">
            <Link
              to={"/panel/part-name/" + row?.part_name?.id}
              className="text-dark fw-bolder text-hover-primary"
            >
              {row.part_name?.name}({row.part_name?.part_number})
            </Link>
          </div>
        </div>
      ),
    },
    {
      name: "Quantity",
      selector: (row) => row.total_qty ?? "--",
      sortable: true,
      field: "qty",
    },
    // {
    //   name: "Selling Price",
    //   selector: (row) => row.unit_price ?? "--",
    //   sortable: true,
    //   field: "unit_price ",
    // },
    // {
    //   name: "Remarks",
    //   selector: (row) => row.remarks ?? "--",
    //   sortable: true,
    //   field: "remarks ",
    // },
    // {
    //   name: "Action",
    //   selector: (row) => row.part_name_id,
    //   maxWidth: "150px",
    //   format: (row) => (
    //     <span className="text-end">
    //       <PermissionAbility permission="parts_show">
    //         <Link
    //           to={"/panel/parts/" + row.id}
    //           className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
    //         >
    //           <i className="fa fa-eye"></i>
    //         </Link>
    //       </PermissionAbility>

    //       {/* <PermissionAbility permission="parts_edit">
    //         <button
    //           className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
    //           onClick={() => {
    //             setPart(row);
    //             setOpenEditModal(true);
    //           }}
    //         >
    //           <i className="fa fa-pen"></i>
    //         </button>
    //       </PermissionAbility> */}

    //       <PermissionAbility permission="parts_delete">
    //         <Link
    //           to="#"
    //           className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
    //           onClick={() => {
    //             setPartId(row.id);
    //             setConfirmDelete(true);
    //           }}
    //         >
    //           <i className="fa fa-trash"></i>
    //         </Link>
    //       </PermissionAbility>
    //     </span>
    //   ),
    // },
  ];

  const filterData = (dt) => {
    setFilter({
      ...filter,
      ...dt,
    });

    setEnableFilter(false);
  };

  const getParts = async (filters) => {
    const data = await PartService.getAll(filters);
    setParts(data);
    setLoading(false);
  };

  const deletePart = async (partId) => {
    await PartService.remove(partId);
    getParts();
    setLoading(false);
  };

  const onCloseModal = () => {
    setOpenAddModal(false);
    setOpenEditModal(false);
    setOpenImportModal(false);
  };

  useEffect(() => {
    if (filter.order)
      //Just to avoid double load
      getParts(filter);
  }, [filter]);

  const ExportParts = async () => {
    let data = await PartService.exportParts(filter);
    window.location.href = data;
  };

  return (
    <>
      <div className="post d-flex flex-column-fluid">
        <div className="container-xxl">
          <Table
            name="Parts"
            // buttonName="a"
            // buttonName="Add Part"
            search
            onClickButton={() => console.log("clicked")}
            buttonPermission="parts_create"
            callbackButtons={[
              {
                name: "Export",
                permission: null,
                callback: () => {
                  ExportParts();
                },
              },
              {
                name: "Filter",
                callback: () => {
                  setEnableFilter(!enableFilter);
                },
                permission: null,
              },
              {
                name: "Add Part",
                callback: () => {
                  setOpenAddModal(!openAddModal);
                },
                permission: "parts_create",
              },
              {
                name: "Add Part from Stock",
                callback: () => {
                  setOpenAddStockModal(!openAddStockModal);
                },
                permission: "parts_create",
              },
            ]}
            isLoading={loading}
            data={parts}
            columns={columns}
            onFilter={filterData}
          />
        </div>
      </div>

      <Confirmation
        open={confirmDelete}
        onConfirm={() => {
          setConfirmDelete(false);
          deletePart(partId);
        }}
        onCancel={() => setConfirmDelete(false)}
      />
      <CreateContract
        open={openAddModal}
        onCloseModal={onCloseModal}
        onCreated={getParts}
      />
      <CreatePartStock
        open={openAddStockModal}
        onCloseModal={() => setOpenAddStockModal(false)}
        onCreated={getParts}
      />
      <EditPart
        open={openEditModal}
        part={part}
        onCloseModal={onCloseModal}
        onUpdated={getParts}
      />
      <ImportFile
        open={openImportModal}
        onCloseModal={onCloseModal}
        onImported={getParts}
      />

      <PartFilter
        enable={enableFilter}
        onClickOutside={() => {
          setEnableFilter(!filter);
        }}
        onChange={filterData}
      />
    </>
  );
};

export default Parts;
