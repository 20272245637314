const PermissionAbility2 = ({ children, permission }) => {
  let data = JSON.parse(localStorage.getItem("user"));
  let user = data.user;
  let permissions = user?.permissions;

  if (user?.role === "Admin") return children;

  if (!permission) return children;

  const res =  permission?.some(item=>permissions?.includes(item))

  return res ? children : null;
};

export default PermissionAbility2;
