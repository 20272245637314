import Table from "components/utils/Table";
import PermissionAbility from "helpers/PermissionAbility";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import QuotationService from "services/QuotationService";
import Confirmation from "components/utils/Confirmation";
import Moment from "react-moment";

const ServiceQuotation = () => {
  const [filter, setFilter] = useState(false);
  const [loading, setLoading] = useState(true);
  const [quotation, setQuotation] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [quoId, setQuoId] = useState(null);

  const filterdata = (data) => {
    setFilter(false);
    getQuotation(data);
  };

  useEffect(() => {
    filterdata();
  }, []);

  const columns = [
    {
      name: "Id",
      selector: (row) => row?.pq_number,
      sortable: true,
      field: "id",
    },
    {
      name: "Company",
      selector: (row) => row?.company?.name,
      sortable: true,
      field: "name",
      format: (row) => (
        <div className="d-flex align-items-center">
          <div className="d-flex justify-content-start flex-column">
            <Link
              to={"/panel/companies/" + row?.company?.id}
              className="text-dark fw-bolder text-hover-primary"
            >
              {row?.company?.name}
            </Link>
          </div>
        </div>
      ),
    },
    {
      name: "Engineer",
      selector: (row) => row?.engineer?.name ?? "--",
      sortable: true,
      field: "engineer",
    },
    {
      name: "Expected Delivery",
      selector: (row) => row?.expected_delivery,
      sortable: true,
      field: "expected_delivery",
      format: (row) => (
        <div className="mt-2">
          {row?.expected_delivery ? row?.expected_delivery : "--"}
        </div>
      ),
    },
    {
      name: "Priority",
      selector: (row) => row.priority,
      sortable: true,
      field: "role",
    },
    {
      name: "Status",
      selector: (row) => row?.status,
      sortable: true,
      field: "role",
      format: (row) => (
        <>
          {row?.status == "pending" && (
            <div className="mt-2 text-white bg-danger p-1 px-2 rounded">
              Pending
            </div>
          )}
          {row?.status == "on-going" && (
            <div className="mt-2 text-white bg-danger p-1 px-2 rounded">
              On Going
            </div>
          )}
          {row?.status == "complete" && (
            <div className="mt-2 text-white bg-success p-1 px-2 rounded">
              Complete
            </div>
          )}
        </>
      ),
    },

    {
      name: "IN Number",
      selector: (row) => row?.invoice,
      sortable: true,
      field: "role",
      format: (row) => (
        <div className="mt-2">
          {row?.invoice
            ? row?.invoice
            : "No invoice yet"}
        </div>
      ),
    },

    {
      name: "Created At",
      selector: (row) => row?.created_at,
      sortable: true,
      field: "created_at",
      format: (row) => (
        <div className="mt-2">
          <Moment format="D MMMM YYYY">{row?.created_at}</Moment>
        </div>
      ),
    },

    {
      name: "Action",
      selector: (row) => row.status,
      format: (row) => (
        <span className="text-end">
          <PermissionAbility permission="service_quotations_show">
            <Link
              to={"/panel/services-quotation/" + row.id}
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
            >
              <i className="fa fa-eye"></i>
            </Link>
          </PermissionAbility>

          {!row?.invoice && (
            <PermissionAbility permission="service_invoice_delete">
              <Link
                to="#"
                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                onClick={() => {
                  setQuoId(row.id);
                  setConfirmDelete(true);
                }}
              >
                <i className="fa fa-trash"></i>
              </Link>
            </PermissionAbility>
          )}
        </span>
      ),
    },
  ];

  const getQuotation = async (filters) => {
    let res = await QuotationService.getAllService(filters);
    setQuotation(res);
    setLoading(false);
  };

  const deletePart = async (quoId) => {
    await QuotationService.removeService(quoId);
    getQuotation();
    setLoading(false);
  };

  useEffect(() => {
    getQuotation();
  }, []);

  let navigate = useNavigate();

  const routeChange = () => {
    let path = `create`;
    navigate(path);
  };
  return (
    <>
      <div className="post d-flex flex-column-fluid">
        <div className="container-xxl">
          <Table
            name="Service Quotations"
            buttonName="Add Service Quotation"
            buttonPermission="service_quotations_create"
            onClickButton={routeChange}
            isLoading={loading}
            data={quotation}
            columns={columns}
            onFilter={filterdata}
          />
        </div>
      </div>
      <Confirmation
        open={confirmDelete}
        onConfirm={() => {
          setConfirmDelete(false);
          deletePart(quoId);
        }}
        onCancel={() => setConfirmDelete(false)}
      />
    </>
  );
};

export default ServiceQuotation;
