import { useEffect, useState } from "react";
import Select from "react-select";
import Modal from "components/utils/Modal";
import LcService from "services/LcServices";

const EditLc = ({ open, lcId, onCloseModal, onUpdated, item }) => {
  const [block, setBlock] = useState(false);
  const [file, setFile] = useState("");
  const [data, setData] = useState(null);

  useEffect(() => {
    setData({
      id: item?.id,
      invoice_to: item?.invoice_to,
      invoice_no: item?.invoice_no,
      shipment_date: item?.shipment_date,
      dispatch_by: item?.dispatch_by,
      currency: item?.currency,
      lc_amount: item?.lc_amount.toString(),
      tt_amount: item?.tt_amount.toString(),
      other_amount: item?.other_amount.toString(),
      total_amount: item?.total_amount,
      description: item?.description,
      file_url: item?.file_url,
      file: file,
    });
  }, [item]);

  useEffect(() => {
    if (data) {
      if (data?.lc_amount?.length && data?.tt_amount?.length && data?.other_amount?.length) {
        setData({
          ...data,
          total_amount: parseInt(data?.lc_amount) + parseInt(data?.tt_amount)+ parseInt(data?.other_amount),
        });
      } else if (data?.lc_amount.length) {
        setData({
          ...data,
          total_amount: parseInt(data?.lc_amount),
        });
      } else if (data?.tt_amount.length) {
        setData({
          ...data,
          total_amount: parseInt(data?.tt_amount),
        });
      }else if (data?.other_amount.length) {
        setData({
          ...data,
          other_amount: parseInt(data?.other_amount),
        });
      }
       else {
        setData({
          ...data,
          total_amount: 0,
        });
      }
    }
  }, [data?.lc_amount, data?.tt_amount,data?.other_amount]);

  const currency = [
    { value: "usd", label: "USD" },
    { value: "bdt", label: "BDT" },
  ];

  const [defaultCurrency, setDefaultCurrency] = useState({
    value: item?.currency,
    label: item?.currency?.replaceAll("_", " ")?.capitalize(),
  });

  const updateLc = async () => {
    setBlock(true);

    let formData = new FormData(document.getElementById("lc-update"));

    await LcService.update(data?.id, formData);
    onUpdated();
    onCloseModal();
    setData({
      invoice_to: "",
      invoice_no: "",
      shipment_date: "",
      dispatch_by: "",
      currency: "",
      lc_amount: "",
      tt_amount: "",
      other_amount: "",
      total_amount: "",
      description: "",
      file: "",
    });
    setBlock(false);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSelect = (option, action) => {
    const value = option.value;
    const name = action.name;
    setData({
      ...data,
      [name]: value,
    });
  };

  return (
    <div>
      <Modal
        size="xl"
        open={open}
        onCloseModal={onCloseModal}
        title={<>Edit LC</>}
        body={
          <>
            <form id="lc-update" encType="multipart/form-data">
              <div className="mb-5 fv-row fv-plugins-icon-container text-center">
                <div
                  className="fv-plugins-message-container invalid-feedback"
                  htmlFor="image"
                ></div>
              </div>

              <div className="form-group row">
                <div className="col-6">
                  <label className="required form-label">Invoice To</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Invoice To"
                    name="invoice_to"
                    id="invoice_to"
                    value={data?.invoice_to ?? ""}
                    onChange={handleChange}
                  />
                  <div
                    className="fv-plugins-message-container invalid-feedback"
                    htmlFor="name"
                  ></div>
                </div>
                <div className="col-6">
                  <label className="required form-label">Invoice No</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Invoice No"
                    name="invoice_no"
                    id="invoice_no"
                    value={data?.invoice_no ?? ""}
                    onChange={handleChange}
                  />
                  <div
                    className="fv-plugins-message-container invalid-feedback"
                    htmlFor="name"
                  ></div>
                </div>

                <div className="col-6">
                  <label className="required form-label">Shipment Date</label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Enter Shipment Date"
                    name="shipment_date"
                    id="shipment_date"
                    value={data?.shipment_date ?? ""}
                    onChange={handleChange}
                  />
                  <div
                    className="fv-plugins-message-container invalid-feedback"
                    htmlFor="name"
                  ></div>
                </div>
                <div className="col-6">
                  <label className="required form-label">Dispatch By</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Dispatch By"
                    name="dispatch_by"
                    id="dispatch_by"
                    value={data?.dispatch_by ?? ""}
                    onChange={handleChange}
                  />
                  <div
                    className="fv-plugins-message-container invalid-feedback"
                    htmlFor="name"
                  ></div>
                </div>

                <div className="col-6">
                  <label className="required form-label">Currency</label>
                  <Select
                    options={currency}
                    defaultValue={defaultCurrency}
                    onChange={(option, action) => handleSelect(option, action)}
                    name="currency"
                    id="currency"
                  />
                  <div
                    className="fv-plugins-message-container invalid-feedback"
                    htmlFor="name"
                  ></div>
                </div>
                <div className="col-6">
                  <label className="required form-label">LC Amount</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Total Amount"
                    name="lc_amount"
                    id="lc_amount"
                    value={data?.lc_amount ?? ""}
                    onChange={handleChange}
                  />
                  <div
                    className="fv-plugins-message-container invalid-feedback"
                    htmlFor="name"
                  ></div>
                </div>
                <div className="col-4">
                  <label className="required form-label">TT Amount</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter TT Amount"
                    name="tt_amount"
                    id="tt_amount"
                    value={data?.tt_amount ?? ""}
                    onChange={handleChange}
                  />
                  <div
                    className="fv-plugins-message-container invalid-feedback"
                    htmlFor="name"
                  ></div>
                </div>

                <div className="col-4">
                  <label className="required form-label">Others Amount</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Others Amount"
                    name="other_amount"
                    id="other_amount"
                    value={data?.other_amount ?? ""}
                    onChange={handleChange}
                  />
                  <div
                    className="fv-plugins-message-container invalid-feedback"
                    htmlFor="name"
                  ></div>
                </div>

                <div className="col-4">
                  <label className="required form-label">Total Amount</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Total Crf Amount"
                    name="total_amount"
                    id="total_amount"
                    value={data?.total_amount ?? ""}
                    onChange={handleChange}
                    disabled
                  />
                  <div
                    className="fv-plugins-message-container invalid-feedback"
                    htmlFor="name"
                  ></div>
                </div>

                
              </div>

              <div className="form-group mt-5">
                <label className="form-label">Description</label>
                <textarea
                  rows="3"
                  type="text"
                  className="form-control"
                  placeholder="Enter Description"
                  name="description"
                  id="description"
                  value={data?.description ?? ""}
                  onChange={handleChange}
                />
                <div
                  className="fv-plugins-message-container invalid-feedback"
                  htmlFor="description"
                ></div>
              </div>

              <button
                type="button"
                disabled={block}
                className="btn btn-primary mr-2 mt-5"
                style={{ marginRight: "1rem" }}
                onClick={() => {
                  updateLc();
                }}
              >
                Submit
              </button>
              <button
                type="button"
                className="btn btn-secondary  mt-5 "
                onClick={onCloseModal}
              >
                Cancel
              </button>
            </form>
          </>
        }
      />
    </div>
  );
};

export default EditLc;
