import PermissionAbility from "helpers/PermissionAbility";
import React from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import InvoiceService from "services/InvoiceService";
import { useEffect, useState } from "react";
import Confirmation from "components/utils/Confirmation";


const PaymentHistores = ({ setOpen, data,onClose }) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [paymentHistoriesId, setPaymentHistoriesId] = useState({});
  const [paymentHistoriesDelete, setPaymentHistoriesDelete] = useState(false);
  const deleteItem = async () => {
      await InvoiceService.removePaymentHistory(paymentHistoriesId);
      onClose()
  };
  return (
    <div className="d-flex flex-column gap-7 gap-lg-10">
      <div className="card card-flush py-4">
        <div className="card-header">
          <div className="card-title">
            <h2>Payment Histories</h2>
          </div>
          <div className="card-toolbar">
            <PermissionAbility permission="invoices_payment">
              <button
                type="button"
                className="btn btn-light-primary"
                onClick={() => {
                  setOpen(true);
                }}
              >
                <span className="svg-icon svg-icon-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.3"
                      x="2"
                      y="2"
                      width="20"
                      height="20"
                      rx="5"
                      fill="black"
                    ></rect>
                    <rect
                      x="10.8891"
                      y="17.8033"
                      width="12"
                      height="2"
                      rx="1"
                      transform="rotate(-90 10.8891 17.8033)"
                      fill="black"
                    ></rect>
                    <rect
                      x="6.01041"
                      y="10.9247"
                      width="12"
                      height="2"
                      rx="1"
                      fill="black"
                    ></rect>
                  </svg>
                </span>
                Add Payment
              </button>
            </PermissionAbility>
          </div>
        </div>
        <div className="card-body pt-0">
          <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
            <thead>
              <tr className="fw-bolder text-muted">
                <th className="min-w-80px">Invoice Id</th>
                <th className="min-w-120px">payment Mode</th>
                <th className="min-w-120px">Payment Date</th>
                <th className="min-w-120px">Amount</th>
                <th className="min-w-120px">VAT/AIT</th>
                <th className="min-w-150x">Action</th>
              </tr>
            </thead>

            <tbody>
              {data?.map((item, index) => (
                <tr key={index}>
                  <td>{item?.invoice?.invoice_number}</td>
                  <td>{item.payment_mode}</td>

                  <td>
                    <Moment format="YYYY-MM-DD">{item.payment_date}</Moment>
                  </td>
                  <td>{Math.floor(item?.amount)}Tk.</td>
                  <td>{item?.vat_ait_received ? "Received" : "N/A"}</td>
                  <td>
                    <span className="text-end">
                      <Link
                        to={
                          `/panel/invoices/` +
                          item?.invoice?.id +
                          `/payment-histories/` +
                          item.id
                        }
                        className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                      >
                        <i className="fa fa-eye"></i>
                      </Link>
                    </span>
                    <PermissionAbility permission="payment_history_delete">
                      <span className="text-end">
                        <button
                          onClick={() => {
                            setPaymentHistoriesId(item?.id);
                            setPaymentHistoriesDelete(true);
                            setConfirmDelete(true);
                          }}
                          className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                        >
                          <i className="fa fa-trash"></i>
                        </button>
                      </span>
                    </PermissionAbility>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Confirmation
        open={confirmDelete}
        onConfirm={() => {
          setConfirmDelete(false);
          deleteItem();
        }}
        onCancel={() => setConfirmDelete(false)}
      />
    </div>
  );
};

export default PaymentHistores;
