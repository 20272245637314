import React from "react";
import { Row, Col } from "react-bootstrap";
import StatisticsChartWidget from "./StatisticsChartWidget";

const Statistics = ({ data, title }) => {
  return (
    <div>
      <Row>
        <Col sm={6} xl={6}>
          <StatisticsChartWidget
            title={title[0]}
            stats={data?.totalSell+' '+'BDT'}
            trend={{
              textClass: "text-success",
              icon: "uil uil-arrow-up",
              // value: "10.21%",
            }}
            colors={["#727cf5"]}
          />
        </Col>

        <Col sm={6} xl={6}>
          <StatisticsChartWidget
            title={title[1]}
            stats={data?.totalPurchase+' '+'BDT'}
            trend={{
              textClass: "text-danger",
              icon: "uil uil-arrow-down",
              // value: "5.05%",
            }}
            colors={["#f77e53"]}
          />
        </Col>  
      </Row>
    </div>
  );
};

export default Statistics;
